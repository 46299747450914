import { HideIcon } from '@f8n/icons';
import { keepPreviousData, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import CardGrid from 'components/CardGrid';
import ArtworkCard, {
  ArtworkCardProps,
} from 'components/cards/artwork/ArtworkCard';
import ArtworkCardSkeletonGrid from 'components/cards/artwork/ArtworkCardSkeletonGrid';
import { useToggleArtworkHiddenMenuOption } from 'components/dropdown/artwork';
import InfiniteScrollButton from 'components/feed/InfiniteScrollButton';
import { useHomeTabCurationOption } from 'components/home-tab';
import { PopoverMenuOption } from 'components/popover/types';
import { hasToken } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useNotifications from 'state/stores/notifications';

import { useInfiniteUserArtworksCollected } from 'gql/hasura/queries/user-artworks-collected.generated';
import { useSetArtworkUserVisibility } from 'gql/server/mutations/set-artwork-user-visibility.generated';
import useAssetFallbackByArtworkIds from 'hooks/queries/hasura/artworks/use-asset-fallback-by-artwork-ids';
import useProfileTabCounts from 'hooks/queries/hasura/profile/use-profile-tab-counts';
import { getBaseKey } from 'hooks/queries/shared';
import { useInfiniteQueryItems } from 'hooks/use-infinite-query-items';
import useModal from 'hooks/use-modal';
import { TEMPORARILY_HIDDEN_CONTRACT_ADDRESSES } from 'lib/addresses';
import { getAggregateCount } from 'utils/aggregate';
import { findFallbackAsset, mapFailedArtworksToIds } from 'utils/assets';
import { hasuraPaginator } from 'utils/react-query';
import { areKeysEqual } from 'utils/users';

import { UserLight } from 'types/Account';
import { HomeTabCurationProps } from 'types/HomeTab';

import FilterLayout from './FilterLayout';
import { ProfileTabsProps } from './types';

type OwnedTabProps = ProfileTabsProps & HomeTabCurationProps;

export default function OwnedTab(props: OwnedTabProps) {
  const auth = useAuth();
  const modal = useModal();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const userArtworksCollectedQuery = useInfiniteUserArtworksCollected(
    {
      publicKey: props.publicKey,
      indexedStates: props.indexedStates,
      hiddenCollections: TEMPORARILY_HIDDEN_CONTRACT_ADDRESSES,
      ...hasuraPaginator.initialPageParam,
    },
    {
      initialPageParam: hasuraPaginator.initialPageParam,
      getNextPageParam: hasuraPaginator.getNextPageParam,
      placeholderData: keepPreviousData,
    }
  );

  const artworks = useInfiniteQueryItems(userArtworksCollectedQuery, 'id');

  const fallbackArtworkIds = mapFailedArtworksToIds(artworks);
  const fallbackAssetsQuery = useAssetFallbackByArtworkIds({
    artworkIds: fallbackArtworkIds,
  });

  const setArtworkUserVisibility = useSetArtworkUserVisibility({
    onSuccess: () => {
      userArtworksCollectedQuery.refetch();
      queryClient.invalidateQueries({
        queryKey: [getBaseKey(useProfileTabCounts.getKey)],
      });
      notifications.show.info({
        message: 'NFT hidden from profile',
        icon: <HideIcon />,
      });
    },
  });

  if (userArtworksCollectedQuery.isLoading) {
    return (
      <FilterLayout filters={null}>
        <ArtworkCardSkeletonGrid items={props.skeletonCardCount} />
      </FilterLayout>
    );
  }

  return (
    <FilterLayout filters={null}>
      <CardGrid.Root>
        {artworks.map((artwork) => {
          const fallbackAsset = findFallbackAsset(
            fallbackAssetsQuery.data?.asset,
            artwork?.id
          );
          const hasSplits = getAggregateCount(artwork?.splitRecipients) > 0;
          const isCreator = areKeysEqual([artwork?.publicKey, props.publicKey]);
          const canHide = (hasSplits && !isCreator) || !isCreator;

          return (
            <OwnedTabArtworkCard
              key={artwork.id}
              artwork={artwork}
              creator={artwork.creator as UserLight}
              fallbackAsset={fallbackAsset}
              homeTabCuration={props.homeTabCuration}
              canCurateHomeTab={props.canCurateHomeTab}
              canHide={canHide}
              isHidden={false}
              onToggleHidden={() => {
                if (!hasToken(auth)) {
                  modal.setModal({ type: 'AUTH_PRE_SIGN' });
                  return;
                }

                if (!artwork.contractAddress || !artwork.tokenId) {
                  return;
                }

                setArtworkUserVisibility.mutate({
                  contractAddress: artwork.contractAddress,
                  shouldHide: true,
                  tokenId: artwork.tokenId,
                });
              }}
              publicKey={props.publicKey}
            />
          );
        })}
      </CardGrid.Root>
      <InfiniteScrollButton
        handleNextPage={userArtworksCollectedQuery.fetchNextPage}
        isFetching={userArtworksCollectedQuery.isFetching}
        hasNextPage={userArtworksCollectedQuery.hasNextPage}
      />
    </FilterLayout>
  );
}

type OwnedTabArtworkCardProps = HomeTabCurationProps &
  Omit<ArtworkCardProps, 'menuOptions'> & {
    canHide: boolean;
    isHidden: boolean;
    onToggleHidden(): void;
    publicKey: string;
  };

function OwnedTabArtworkCard(props: OwnedTabArtworkCardProps) {
  const {
    homeTabCuration,
    canCurateHomeTab,
    canHide,
    isHidden,
    onToggleHidden,
    publicKey,
  } = props;
  const id = props.artwork.id;

  const homeTabOption = useHomeTabCurationOption({
    homeTabCuration,
    canCurateHomeTab,
    nftId: id,
    type: 'profile',
  });

  const toggleHiddenMenuOption = useToggleArtworkHiddenMenuOption(
    {
      contractAddress: props.artwork.contractAddress,
      tokenId: props.artwork.tokenId,
      shouldHide: !isHidden,
      publicKey,
    },
    {
      onMutate: () => {
        // TODO: optimistically remove the item from the list
      },
      onSuccess: () => onToggleHidden(),
    }
  );

  const menuOptions = useMemo(() => {
    const options: PopoverMenuOption[] = [];

    if (canHide) {
      options.push(toggleHiddenMenuOption);
    }

    if (homeTabOption) {
      options.push(homeTabOption);
    }

    return options;
  }, [homeTabOption]);

  return <ArtworkCard {...props} menuOptions={menuOptions} />;
}
