import { useQuery } from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/exhibitions-by-curator.generated';

async function getWorldsByCurator(
  variables: Generated.ApiExhibitionsByCuratorVariables
) {
  const fetchExhibitionsByCurator =
    Generated.useExhibitionsByCurator.fetcher(variables);

  const response = await fetchExhibitionsByCurator();
  return response.exhibitionByCurator ? response.exhibitionByCurator : [];
}

export default function useWorldsByCurator(
  variables: Generated.ApiExhibitionsByCuratorVariables
) {
  return useQuery({
    queryKey: ['world', 'listByCurator', variables],
    queryFn: () => getWorldsByCurator(variables),
  });
}
