export const validSocialLinkTypes = [
  'website',
  'discord',
  'youtube',
  'facebook',
  'twitch',
  'tiktok',
  'snapchat',
] as const;

export type SocialLinkType = (typeof validSocialLinkTypes)[number];

export interface SocialLink {
  platform: SocialLinkType;
  handle: string;
}
