import { Formik, Form } from 'formik';

import Box from 'components/base/Box';
import Grid from 'components/base/Grid';
import CheckboxAndWrapper from 'components/forms/CheckboxAndWrapper';
import FormikSubmitButton from 'components/forms/FormikSubmitButton';

import { useSetFeatureFlags } from 'gql/api/mutations/set-feature-flags.generated';
import { useFeatureFlagsByUser } from 'gql/api/queries/feature-flags-by-user.generated';

interface SetFeatureFlagsPaneValues {
  allowlistAccess: boolean;
  dropsAccess: boolean;
}

interface SetFeatureFlagsPaneProps {
  publicKey: string;
}

export default function SetFeatureFlagsPane(props: SetFeatureFlagsPaneProps) {
  const { publicKey } = props;

  const { data, isLoading } = useFeatureFlagsByUser({ userAddress: publicKey });
  const hasAllowListAccess = Boolean(data?.featureFlagsByUser.allowlistAccess);
  const hasDropsAccess = Boolean(data?.featureFlagsByUser.dropsAccess);

  const setFeatureFlags = useSetFeatureFlags();

  const handleSubmit = async (values: SetFeatureFlagsPaneValues) => {
    await setFeatureFlags.mutateAsync({
      flags: {
        allowListAccess: values.allowlistAccess,
        dropsAccess: values.dropsAccess,
      },
      userAddress: publicKey,
    });
  };

  if (isLoading || !data) return null;

  return (
    <Box>
      <Formik<SetFeatureFlagsPaneValues>
        initialValues={{
          allowlistAccess: hasAllowListAccess,
          dropsAccess: hasDropsAccess,
        }}
        onSubmit={handleSubmit}
        enableReinitialize={!publicKey}
      >
        <Form>
          <Grid css={{ gap: '$4' }}>
            <CheckboxAndWrapper
              name="allowlistAccess"
              label="Allowlist access"
              description="Give user allowlist access?"
            />
            <CheckboxAndWrapper
              name="dropsAccess"
              label="Drops access"
              description="Give user drops access?"
            />

            <FormikSubmitButton
              label="Save"
              submittingLabel="Saving…"
              submittedLabel="Saved"
              variant="danger"
            />
          </Grid>
        </Form>
      </Formik>
    </Box>
  );
}
