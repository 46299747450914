import { useNftFilters } from 'hooks/filters/use-nft-filters';
import { ArtworkCollectionFilterItem } from 'hooks/use-artwork-collection-filter';

import { UnsafeJsonBlob } from 'types/utils';

import MultiSelectOption from './MultiSelectOption';

interface CollectionMultiSelectProps {
  publicKey: string;
  collections: ArtworkCollectionFilterItem[];
  counts: UnsafeJsonBlob | undefined;
}

export default function CollectionMultiSelect(
  props: CollectionMultiSelectProps
) {
  const { publicKey, collections, counts } = props;
  const { filters, setCollectionFilter } = useNftFilters(publicKey);

  return (
    <>
      {collections.map((collection) => {
        const { contractAddress } = collection;
        const count = (counts && counts[contractAddress]) || 0;
        return (
          <MultiSelectOption
            key={collection.contractAddress}
            // @ts-expect-error null-checks
            collection={collection}
            checked={(filters && filters.collections[contractAddress]) ?? false}
            count={count}
            onCheckedChange={(checked) => {
              setCollectionFilter(contractAddress, checked);
            }}
          />
        );
      })}
    </>
  );
}
