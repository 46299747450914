import { IconEl } from 'components/base/IconV2';

import DiscordIcon from 'assets/icons/discord.svg';
import FacebookIcon from 'assets/icons/facebook.svg';
import SnapChatIcon from 'assets/icons/snapchat.svg';
import TikTokIcon from 'assets/icons/tiktok.svg';
import TwitchIcon from 'assets/icons/twitch.svg';
import WebsiteIcon from 'assets/icons/website.svg';
import YouTubeIcon from 'assets/icons/youtube.svg';
import { UserProfileFragment } from 'gql/hasura/hasura-fragments.generated';

import {
  SocialLink,
  SocialLinkType,
  validSocialLinkTypes,
} from 'types/SocialLink';

const getInitialSocialLinks = (socialLinks) =>
  socialLinks.reduce(
    (obj, item) => ({
      ...obj,
      [item.type]: {
        platform: item.type,
        handle: '',
      },
    }),
    {}
  );

export const mergeSocialLinks = (socialLinks, userProfileLinks) => {
  const initialLinks = getInitialSocialLinks(socialLinks);
  const updates = {};

  // Strip __typename and update initial links with DB data
  if (userProfileLinks) {
    Object.keys(userProfileLinks).forEach((platform) => {
      if (platform != '__typename') {
        const link = userProfileLinks[platform];

        if (link) {
          const _updates = {};
          Object.keys(link).forEach((field) => {
            if (field != '__typename') {
              _updates[field] = link[field];
            }
          });
          updates[platform] = _updates;
        }
      }
    });
  }
  return { ...initialLinks, ...updates };
};

export const getIdentityMenuLinks = (
  user: UserProfileFragment | null | undefined
) => {
  if (!user || !user.links) {
    return [];
  }

  return validSocialLinkTypes
    .map((socialService) => {
      const socialLink: SocialLink | undefined = user.links[socialService];
      if (socialLink && socialLink.handle) {
        return {
          platform: socialService,
          handle: socialLink.handle,
        };
      } else {
        return undefined;
      }
    })
    .filter((link): link is SocialLink => Boolean(link));
};

type SocialFormLink = {
  title: string;
  type: SocialLinkType;
  placeholder: string;
  urlPrefix: string;
};

export const getIcon = (platform: SocialLink['platform']): IconEl => {
  const iconMap: Record<SocialLink['platform'], IconEl> = {
    discord: DiscordIcon,
    facebook: FacebookIcon,
    snapchat: SnapChatIcon,
    tiktok: TikTokIcon,
    twitch: TwitchIcon,
    website: WebsiteIcon,
    youtube: YouTubeIcon,
  };

  return iconMap[platform];
};

export const socialLinks: SocialFormLink[] = [
  {
    title: 'Website',
    type: 'website',
    placeholder: 'Website URL',
    urlPrefix: 'https://',
  },
  {
    title: 'Discord',
    type: 'discord',
    urlPrefix: '',
    placeholder: 'Discord Username',
  },
  {
    title: 'YouTube',
    type: 'youtube',
    urlPrefix: '',
    placeholder: 'Channel URL',
  },
  {
    title: 'Facebook',
    type: 'facebook',
    urlPrefix: 'facebook.com/',
    placeholder: 'Facebook Username',
  },
  {
    title: 'Twitch',
    type: 'twitch',
    urlPrefix: 'twitch.tv/',
    placeholder: 'Twitch Username',
  },
  {
    title: 'TikTok',
    type: 'tiktok',
    urlPrefix: 'tiktok.com/',
    placeholder: 'TikTok Username',
  },
  {
    title: 'Snapchat',
    type: 'snapchat',
    urlPrefix: '',
    placeholder: 'Snapchat Username',
  },
];
