import { CheckIcon, LockIcon, RemoveIcon, WarningIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik, useField } from 'formik';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { useLocation } from 'react-use';

import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Card from 'components/base/Card';
import Grid from 'components/base/Grid';
import Text from 'components/base/Text';
import TextField from 'components/forms/fields/TextField';

import { SetArtworkModerationProxyVariables } from 'queries/admin/artwork';
import { SetCollectionModerationProxyVariables } from 'queries/admin/collection';
import { SetProfileModerationProxyProps } from 'queries/admin/profile';

import { ModerationStatus } from 'types/Moderation';
import { Maybe } from 'types/utils';

type ChangeStatusMutationArgs =
  | SetArtworkModerationProxyVariables
  | SetProfileModerationProxyProps
  | SetCollectionModerationProxyVariables;

interface ChangeStatusPaneProps {
  moderationStatus: ModerationStatus;
  moderationFrom: Maybe<string> | undefined;
  currentUserPublicKey: string;
  entityId: string;
  tokenId?: string;
  mutation: (arg0: ChangeStatusMutationArgs) => Promise<{ done: boolean }>;
}

interface ChangeStatusFormValues {
  moderationStatus: ModerationStatus;
  moderationFrom: string;
  id: string;
}

/**
 * @deprecated - replace with accessible RadioGroup or Select
 */
export default function ChangeStatusPane(props: ChangeStatusPaneProps) {
  const {
    moderationStatus,
    moderationFrom,
    currentUserPublicKey,
    mutation,
    entityId,
    tokenId,
  } = props;

  const router = useRouter();
  const { href } = useLocation();

  const {
    mutateAsync: moderationStatusMutation,
    isPending: moderationMutationLoading,
    isSuccess: moderationMutationSuccess,
  } = useMutation({
    mutationFn: mutation,
    onSuccess: () => {
      router.reload();
    },
  });

  const isLoading = moderationMutationLoading || moderationMutationSuccess;

  const handleSetStatus = async (values: ChangeStatusFormValues) => {
    if (
      window.confirm(
        `Are you sure you want to change the status of this page to: ${values.moderationStatus}?`
      )
    ) {
      await moderationStatusMutation({
        ...values,
        // @ts-expect-error null-checks
        tokenId,
        adminAddress: currentUserPublicKey,
        // @ts-expect-error null-checks
        url: href,
      });
    }
  };

  return (
    <Box>
      <Formik<ChangeStatusFormValues>
        initialValues={{
          moderationStatus: moderationStatus,
          // @ts-expect-error null-checks
          moderationFrom: moderationFrom,
          id: entityId,
        }}
        onSubmit={handleSetStatus}
      >
        <Form>
          <Box
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '$4',
              svg: {
                color: '$black50',
                marginRight: '$4',
              },
            }}
          >
            <Status status="ACTIVE">
              <>
                <Text
                  weight="semibold"
                  size={2}
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '$2',
                  }}
                >
                  <CheckIcon size={2} />
                  Public
                </Text>
                <OptionText>
                  This page will be publicly viewable across Foundation. This is
                  the default state for pages on Foundation.
                </OptionText>
              </>
            </Status>
            <Status status="UNDER_REVIEW">
              <>
                <Text
                  weight="semibold"
                  size={2}
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '$2',
                  }}
                >
                  <WarningIcon size={2} />
                  Under Review
                </Text>
                <OptionText>
                  This page will be taken down and replaced with an ‘Under
                  Review’ message. It will only be accessible via direct link.
                </OptionText>
              </>
            </Status>
            <Status status="TAKEDOWN_REQUESTED">
              <>
                <Text
                  weight="semibold"
                  size={2}
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '$2',
                  }}
                >
                  <LockIcon size={2} />
                  DMCA Complaint
                </Text>
                <Grid css={{ gap: '$3' }}>
                  <OptionText>
                    This page will be taken down and replaced with a DMCA
                    message. It will only be accessible via direct link.
                  </OptionText>
                  <TextField
                    name="moderationFrom"
                    label="Complaint submitted by"
                    required={false}
                  />
                </Grid>
              </>
            </Status>
            <Status status="SUSPENDED">
              <>
                <Text
                  weight="semibold"
                  size={2}
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '$2',
                  }}
                >
                  <RemoveIcon />
                  Remove
                </Text>
                <OptionText>
                  This page will be taken down and replaced with a ‘Permanently
                  Removed’ message. It will only be accessible via direct link.
                </OptionText>
              </>
            </Status>
            <Button
              size={2}
              disabled={isLoading}
              type="submit"
              variant="danger"
            >
              {isLoading ? 'Saving…' : 'Save'}
            </Button>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
}

const OptionText = styled(Text, {
  defaultVariants: {
    color: 'dim',
    size: 0,
    lineHeight: 3,
  },
});

interface StatusProps {
  children: ReactNode;
  status: ModerationStatus;
}

/**
 * @deprecated - replace with accessible RadioGroup or Select
 */
function Status(props: StatusProps) {
  const { children, status } = props;

  const [field, , helpers] = useField<ModerationStatus>('moderationStatus');

  return (
    <Card
      css={{
        textAlign: 'left',
        padding: '$3 $5',
        fontFamily: '$body',
        border: '2px solid transparent',
        borderColor: field.value === status ? '$black100' : 'transparent',
      }}
      onClick={() => helpers.setValue(status)}
      isInteractive
    >
      {children}
    </Card>
  );
}
