import { keepPreviousData } from '@tanstack/react-query';
import { useEffect } from 'react';

import CardGrid from 'components/CardGrid';
import Empty from 'components/Empty';
import InfiniteScroll from 'components/InfiniteScroll';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import ArtworkCardAlgolia, {
  ArtworkCardAlgoliaProps,
} from 'components/cards/artwork/ArtworkCardAlgolia';
import ArtworkCardSkeletonGrid, {
  ArtworkCardSkeletons,
} from 'components/cards/artwork/ArtworkCardSkeletonGrid';
import InfiniteScrollButton from 'components/feed/InfiniteScrollButton';
import { CollectionFilters } from 'components/filters/CollectionFilters';
import InlineFilterGroupDivider from 'components/filters/InlineFilterGroupDivider';
import { MarketAvailabilityContainer } from 'components/filters/MarketAvailabilityFilter';
import NftsSort from 'components/filters/NftsSort';
import MarketAvailabilityFilter from 'components/filters/api/MarketAvailabilityFilter';
import { useHomeTabCurationOption } from 'components/home-tab';

import { useInfiniteNfts } from 'gql/api/queries/nfts.generated';
import { useNftFilters } from 'hooks/filters/use-nft-filters';
import useAssetFallbackByArtworkIds from 'hooks/queries/hasura/artworks/use-asset-fallback-by-artwork-ids';
import useArtworkCollectionFilter from 'hooks/use-artwork-collection-filter';
import { findFallbackAsset, mapFailedArtworksToIds } from 'utils/assets';
import { getFirstValue, notEmptyOrNil } from 'utils/helpers';
import { getCollectionAddressFilters } from 'utils/home-tab/utils';
import { apiPaginator, extractNestedPaginatedData } from 'utils/react-query';

import { HomeTabCurationProps } from 'types/HomeTab';
import { AlgoliaArtworkWithAssetKey } from 'types/Nft';
import { UnsafeJsonBlob } from 'types/utils';

import FilterLayout from './FilterLayout';
import FiltersSkeleton from './FiltersSkeleton';
import { ProfileTabsProps } from './types';

type CreatedTabProps = ProfileTabsProps &
  HomeTabCurationProps & {
    isOwnerOrAdmin: boolean;
  };

export default function CreatedTab(props: CreatedTabProps) {
  const { filters, resetMaketAvailabilityFilter, setFilters } = useNftFilters(
    props.publicKey
  );

  const collectionFilters = getCollectionAddressFilters(filters);

  const distinct =
    !filters.marketAvailability && collectionFilters?.length === 0;

  useEffect(() => {
    setFilters({ sortOrder: 'MINT_DATE_DESC' });
  }, []);

  const { data, isLoading, hasNextPage, isFetching, fetchNextPage } =
    useInfiniteNfts(
      {
        accountAddress: props.publicKey,
        marketAvailability: filters?.marketAvailability || null,
        collectionAddresses: collectionFilters,
        sort: filters?.sortOrder || null,
        distinct,
      },
      {
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => {
          return apiPaginator.getNextPageParam({
            ...lastPage.nftsSearch,
            items: Array.isArray(lastPage.nftsSearch.items)
              ? lastPage.nftsSearch.items
              : [],
          });
        },
        initialPageParam: apiPaginator.initialPageParam,
      }
    );

  const { items: unknownNfts } = extractNestedPaginatedData(data, 'nftsSearch');
  const nfts = unknownNfts as AlgoliaArtworkWithAssetKey[];

  const firstPage = data ? getFirstValue(data.pages) : undefined;
  const counts = firstPage ? firstPage.nftsSearch.counts : undefined;
  const collectionCounts = counts ? counts.collectionCounts : {};
  const distinctCounts = counts ? counts.distinctCounts : {};
  const marketAvailabilityCounts = counts
    ? counts.marketAvailabilityCounts
    : {};

  const fallbackArtworkIds = mapFailedArtworksToIds(nfts);

  const fallbackAssetsQuery = useAssetFallbackByArtworkIds({
    artworkIds: fallbackArtworkIds,
  });

  const { collections } = useArtworkCollectionFilter(props.publicKey);

  if (isLoading) {
    return (
      <FilterLayout filters={<FiltersSkeleton />}>
        <ArtworkCardSkeletonGrid items={props.skeletonCardCount} />
      </FilterLayout>
    );
  }

  return (
    <FilterLayout
      filters={
        <Flex
          css={{
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '$2',
          }}
        >
          <MarketAvailabilityContainer>
            <MarketAvailabilityFilter
              cacheKey={props.publicKey}
              counts={marketAvailabilityCounts}
            />
            {collections.length > 1 && (
              <InlineFilterGroupDivider
                css={{
                  alignSelf: 'center',
                  '@bp2-max': {
                    display: 'none',
                  },
                }}
              />
            )}
          </MarketAvailabilityContainer>
          {collections.length > 1 && (
            <Flex css={{ gap: '$2', flexGrow: 1 }}>
              <CollectionFilters
                collections={collections}
                publicKey={props.publicKey}
                counts={collectionCounts}
              />
            </Flex>
          )}
          <Box css={{ marginLeft: 'auto' }}>
            <NftsSort cacheKey={props.publicKey} />
          </Box>
        </Flex>
      }
    >
      {notEmptyOrNil(nfts) ? (
        <>
          <CardGrid.Root>
            {nfts.map((artwork) => {
              const fallbackAsset = findFallbackAsset(
                fallbackAssetsQuery.data?.asset,
                artwork?.id
              );
              return (
                <CreatedTabArtworkCard
                  key={artwork.objectID}
                  artwork={artwork}
                  marketAvailability={filters.marketAvailability}
                  sortOrder={filters.sortOrder}
                  fallbackAsset={fallbackAsset}
                  homeTabCuration={props.homeTabCuration}
                  canCurateHomeTab={props.canCurateHomeTab}
                  distinctCounts={distinctCounts}
                  distinct={distinct}
                />
              );
            })}
            {hasNextPage && (
              <>
                <InfiniteScroll
                  handleInView={() => {
                    fetchNextPage();
                  }}
                >
                  <ArtworkCardSkeletons items={1} animated />
                </InfiniteScroll>
                <ArtworkCardSkeletons items={3} animated />
                <InfiniteScroll
                  handleInView={() => {
                    fetchNextPage();
                  }}
                />
              </>
            )}
          </CardGrid.Root>
        </>
      ) : (
        <Empty.WithFilters
          message="Adjust the selected filters to see more NFTs."
          onClear={() => resetMaketAvailabilityFilter()}
        />
      )}
      <InfiniteScrollButton
        handleNextPage={fetchNextPage}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
      />
    </FilterLayout>
  );
}

type CreatedTabArtworkCardProps = HomeTabCurationProps &
  Omit<ArtworkCardAlgoliaProps, 'menuOptions'> & {
    distinctCounts: UnsafeJsonBlob;
    distinct: boolean;
  };

function CreatedTabArtworkCard(props: CreatedTabArtworkCardProps) {
  const { homeTabCuration, canCurateHomeTab, distinctCounts, distinct } = props;

  const id = props.artwork.id;
  const distinctAssetKey = props.artwork.distinctAssetKey;

  const homeTabOption = useHomeTabCurationOption({
    homeTabCuration,
    canCurateHomeTab,
    nftId: id,
    type: 'profile',
  });

  const menuOptions = homeTabOption ? [homeTabOption] : [];

  if (props.marketAvailability === null) {
    const itemCount =
      distinct && distinctAssetKey && distinctCounts
        ? distinctCounts && distinctCounts[distinctAssetKey]
        : 0;

    return (
      <ArtworkCardAlgolia
        {...props}
        itemCount={itemCount}
        menuOptions={menuOptions}
        variant={itemCount > 1 ? 'stack' : 'nft'}
      />
    );
  }

  return <ArtworkCardAlgolia {...props} menuOptions={menuOptions} />;
}
