import { onGrid } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import { CSSProperties, VariantProps } from '@stitches/react';

import Body from 'components/base/Body';
import Overlay from 'components/base/Overlay';

import { createCanvasBackground } from 'utils/styles';

type CoverVariants = VariantProps<typeof Cover>;

interface CoverImageProps {
  imageUrl?: string | null;
  children?: React.ReactNode;
  size?: CoverVariants['size'];
}

function CoverImageBase(props: CoverImageProps) {
  const { children, imageUrl, size = 0 } = props;

  const backgroundStyles: CSSProperties = imageUrl
    ? { backgroundImage: `url(${imageUrl})` }
    : createCanvasBackground({});

  return (
    <Relative>
      <Relative>
        {imageUrl && <Overlay />}
        <Cover size={size} style={backgroundStyles} />
      </Relative>
      <Body css={{ height: 0 }}>{children}</Body>
    </Relative>
  );
}

const Relative = styled('div', {
  position: 'relative',
});

const Cover = styled('div', {
  backgroundSize: 'cover',
  backgroundColor: '$black5',
  backgroundPosition: 'center',

  variants: {
    size: {
      0: {
        height: 170,
        '@bp1': {
          height: 240,
        },
      },
      1: {
        height: 240,
        '@bp1': {
          height: 420,
        },
      },
    },
  },
});

const CoverImageAvatarPosition = styled('div', {
  zIndex: 3, // above Overlay
  bottom: 0,
  left: 'auto',
  position: 'absolute',
  variants: {
    position: {
      center: {
        left: '50%',
        transform: 'translate(-50%, 50%)',
      },
      left: {
        transform: `translateY(${onGrid(5)}px)`,
        '@bp1': {
          transform: `translateY(${onGrid(9)}px)`,
        },
      },
    },
  },
});

CoverImageAvatarPosition.defaultProps = {
  position: 'center',
};

const CoverImageWrapper = styled('div', {
  [`${Body}`]: {
    maxWidth: '500px',
    width: '100%',

    '@bp2': {
      maxWidth: '1180px',
    },
  },
});

const CoverImage = Object.assign(CoverImageBase, {
  AvatarPosition: CoverImageAvatarPosition,
  Root: CoverImageWrapper,
});

export default CoverImage;
