import { Formik, Form } from 'formik';

import Box from 'components/base/Box';
import Grid from 'components/base/Grid';
import CheckboxAndWrapper from 'components/forms/CheckboxAndWrapper';
import FormikSubmitButton from 'components/forms/FormikSubmitButton';

import { useRemoveWeb2Data } from 'gql/server/mutations/remove-web2-data.generated';

interface RemoveWeb2DataFormValues {
  userPublicKey: string;
  removeWeb2Data: boolean;
}

interface RemoveWeb2DataPaneProps {
  userPublicKey: string;
}

export default function RemoveWeb2DataPane(props: RemoveWeb2DataPaneProps) {
  const { userPublicKey } = props;

  const { mutateAsync: removWeb2Data } = useRemoveWeb2Data();

  const handleSubmit = async (values: RemoveWeb2DataFormValues) => {
    if (values.removeWeb2Data) {
      await removWeb2Data({
        userPublicKey: values.userPublicKey,
      });
    }
  };

  return (
    <Box>
      <Formik<RemoveWeb2DataFormValues>
        initialValues={{
          userPublicKey,
          removeWeb2Data: false,
        }}
        onSubmit={handleSubmit}
        enableReinitialize={!userPublicKey}
      >
        <Form>
          <Grid css={{ gap: '$4' }}>
            <CheckboxAndWrapper
              name="removeWeb2Data"
              label="Remove Web2 data"
              description="Do you want to remove all Web2 data associated with this user?"
            />

            <FormikSubmitButton
              label="Remove Web2 data"
              submittingLabel="Removing Web2 data…"
              submittedLabel="Web2 data removed"
              variant="danger"
            />
          </Grid>
        </Form>
      </Formik>
    </Box>
  );
}
