import { keepPreviousData } from '@tanstack/react-query';
import { match, P } from 'ts-pattern';

import CardGrid from 'components/CardGrid';
import CollectionCard from 'components/cards/collections/CollectionCard';
import CollectionCardSkeletonGrid from 'components/cards/collections/CollectionCardSkeletonGrid';
import InfiniteScrollButton from 'components/feed/InfiniteScrollButton';

import {
  useInfiniteUserCollections,
  UserCollections,
} from 'gql/hasura/queries/user-collections.generated';
import { useInfiniteQueryItems } from 'hooks/use-infinite-query-items';
import { TEMPORARILY_HIDDEN_CONTRACT_ADDRESSES } from 'lib/addresses';
import { FOUNDATION_CONTRACT_CREATOR_ADDRESS } from 'lib/constants';
import { buildLegacyEditionPosterAsset } from 'utils/assets';
import { getCollectionUrlSlug, isSharedContract } from 'utils/collections';
import { optimizeAsset } from 'utils/imgix';
import { hasuraPaginator } from 'utils/react-query';
import { getPath } from 'utils/router';

import FilterLayout from './FilterLayout';
import { ProfileTabsProps } from './types';

type CollectionsTabProps = ProfileTabsProps;

type UserCollection = UserCollections['userCollections'][number];

//
const mapUserCollections = (data: UserCollections) => {
  return {
    items: [...data.userCollections, ...data.ownedCollections],
  };
};

export default function CollectionsTab(props: CollectionsTabProps) {
  const userCollectionsQuery = useInfiniteUserCollections(
    {
      publicKey: props.publicKey,
      indexedStates: props.indexedStates,
      hiddenCollections: TEMPORARILY_HIDDEN_CONTRACT_ADDRESSES,
      ...hasuraPaginator.initialPageParam,
    },
    {
      initialPageParam: hasuraPaginator.initialPageParam,
      getNextPageParam: (lastPage, allPages) => {
        return hasuraPaginator.getNextPageParam(
          mapUserCollections(lastPage),
          allPages.map(mapUserCollections)
        );
      },
      placeholderData: keepPreviousData,
      select: (data) => ({
        pageParams: data.pageParams,
        pages: data.pages.map(mapUserCollections),
      }),
    }
  );

  const collections = useInfiniteQueryItems(userCollectionsQuery, 'id');

  if (userCollectionsQuery.isLoading) {
    return (
      <FilterLayout filters={null}>
        <CollectionCardSkeletonGrid items={props.skeletonCardCount} />
      </FilterLayout>
    );
  }

  const isFoundationProfile =
    props.publicKey === FOUNDATION_CONTRACT_CREATOR_ADDRESS;

  return (
    <FilterLayout filters={null}>
      <CardGrid.Root>
        {collections.map((collection) => {
          const isShared = isSharedContract(collection.contractType);
          const urlSlug = getCollectionUrlSlug(collection);

          const shouldFilterCollectionPage = isShared && !isFoundationProfile;

          const mappedCollection = mapCollectionProps(collection);

          return (
            <CollectionCard.Connected
              key={collection.id}
              href={
                shouldFilterCollectionPage
                  ? getPath.collection.byCreator(urlSlug, props.publicKey)
                  : undefined
              }
              collection={mappedCollection}
              creator={mappedCollection.creator}
              hasSplits={collection.splits.length > 0}
              nftCount={0}
            />
          );
        })}
      </CardGrid.Root>
      <InfiniteScrollButton
        handleNextPage={userCollectionsQuery.fetchNextPage}
        isFetching={userCollectionsQuery.isFetching}
        hasNextPage={userCollectionsQuery.hasNextPage}
      />
    </FilterLayout>
  );
}

export const mapCollectionProps = (
  collection: UserCollection
): UserCollection => {
  return match(collection)
    .with(
      {
        contractType: P.union('TIMED_EDITION', 'LIMITED_EDITION'),
        editionArtworks: P.array({
          assetScheme: P.string,
          assetHost: P.string,
          assetPath: P.string,
          mimeType: P.string,
        }),
      },
      (collection) => {
        const artwork = collection.editionArtworks[0];

        if (!artwork) {
          return collection;
        }

        if (
          artwork.mimeType === 'video/mp4' ||
          artwork.mimeType === 'video/quicktime'
        ) {
          return {
            ...collection,
            coverImageUrl: buildLegacyEditionPosterAsset(artwork),
            collectionImageUrl: buildLegacyEditionPosterAsset(artwork),
          };
        }

        // We need the URL constructor to auto-append and de-dupe the forward-slash / in the URL (as values from the DB could be in either form)
        const imageUrl = new URL(
          artwork.assetPath,
          artwork.assetScheme + artwork.assetHost
        ).toString();

        const coverImageUrl = optimizeAsset(imageUrl, {
          'max-h': 490,
          'max-w': 370,
          fit: 'crop',
        });
        const collectionImageUrl = optimizeAsset(imageUrl, {
          q: 50,
          w: 72,
          h: 72,
          fit: 'crop',
          dpr: 2,
        });

        return {
          ...collection,
          coverImageUrl,
          collectionImageUrl,
        };
      }
    )
    .otherwise((collection) => collection);
};
