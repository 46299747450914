import { onGrid } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import { ReactNode } from 'react';

import CoverImage from 'components/base/CoverImage';
import UserAvatar from 'components/base/UserAvatar';

interface ProfileCoverImageProps {
  avatar: string | null;
  coverImage: string | null;
  meta?: ReactNode;
  publicKey: string;
}

export default function ProfileCoverImage(props: ProfileCoverImageProps) {
  const { avatar, coverImage, meta, publicKey } = props;

  return (
    <Container>
      <CoverImage imageUrl={coverImage}>
        <CoverImage.AvatarPosition position="left">
          <UserAvatar
            imageUrl={avatar}
            publicKey={publicKey}
            size={{ '@initial': 7, '@bp2': 9 }}
            variant="white"
          />
        </CoverImage.AvatarPosition>
        <Meta>{meta}</Meta>
      </CoverImage>
    </Container>
  );
}

const Container = styled('div', {
  marginBottom: onGrid(9),
  '@bp1': {
    marginBottom: onGrid(15),
  },
});

const Meta = styled('div', {
  zIndex: 3, // Above CoverImage's overlay
  display: 'flex',
  position: 'relative',
  justifyContent: 'flex-end',
});
