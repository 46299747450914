import { styled } from '@f8n-frontend/stitches';

const WorldCardGrid = styled('div', {
  gap: '$6',
  display: 'grid',
  gridTemplateColumns: '1fr',

  '@bp2': {
    gridTemplateColumns: '1fr 1fr',
  },
});

export default WorldCardGrid;
