import { Formik, Form } from 'formik';

import Box from 'components/base/Box';
import Grid from 'components/base/Grid';
import CheckboxAndWrapper from 'components/forms/CheckboxAndWrapper';
import FormikSubmitButton from 'components/forms/FormikSubmitButton';

import { useToggleBlockFollow } from 'gql/api/mutations/toggle-block-follow.generated';
import { useFollowBlocked } from 'gql/api/queries/user-follow-blocked.generated';

interface RemoveWeb2DataFormValues {
  blockFollow: boolean;
}

interface BlockFollowsPaneProps {
  publicKey: string;
}

export default function BlockFollowsPane(props: BlockFollowsPaneProps) {
  const { publicKey } = props;

  const followBlockedQuery = useFollowBlocked(
    { accountAddress: publicKey },
    {
      refetchOnMount: true,
    }
  );
  const followMutation = useToggleBlockFollow();
  const isFollowBlocked = Boolean(
    followBlockedQuery.data &&
      followBlockedQuery.data.followBlocked?.adminFields?.followBlockedAt
  );

  const handleSubmit = async (values: RemoveWeb2DataFormValues) => {
    await followMutation.mutateAsync({
      accountAddress: publicKey,
      blocked: values.blockFollow,
    });
    await followBlockedQuery.refetch();
  };

  return (
    <Box>
      <Formik<RemoveWeb2DataFormValues>
        initialValues={{
          blockFollow: isFollowBlocked,
        }}
        onSubmit={handleSubmit}
        enableReinitialize={!publicKey}
      >
        <Form>
          <Grid css={{ gap: '$4' }}>
            <CheckboxAndWrapper
              name="blockFollow"
              label="Block follows"
              description="Block follow permissions?"
            />

            <FormikSubmitButton
              label="Save"
              submittingLabel="Saving…"
              submittedLabel="Saved"
              variant="danger"
            />
          </Grid>
        </Form>
      </Formik>
    </Box>
  );
}
