import { UseQueryOptions } from '@tanstack/react-query';

import {
  useUserOneTimeActions,
  UserOneTimeActions,
  UserOneTimeActionsVariables as UserOneTimeActionsBaseVariables,
} from 'gql/hasura/queries/user-one-time-actions.generated';
import { isQueryEnabled } from 'hooks/queries/shared';
import { isAllTrue, notEmptyOrNil } from 'utils/helpers';

import { ActionType } from 'types/ActionType';

type UserOneTimeActionsVariables = Omit<
  UserOneTimeActionsBaseVariables,
  'actionType'
> & {
  actionType: ActionType;
};

export default function useUserHasApprovedAction(
  variables: UserOneTimeActionsVariables,
  options?: UseQueryOptions<UserOneTimeActions, Error, boolean>
) {
  return useUserOneTimeActions(variables, {
    ...options,
    select: (data) => notEmptyOrNil(data.oneTimeActions),
    enabled: isAllTrue([...Object.values(variables), isQueryEnabled(options)]),
  });
}
