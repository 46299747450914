import { useToggle } from 'react-use';

import Box from 'components/base/Box';
import ButtonFilterIndicator from 'components/base/ButtonFilterIndicator';
import ChevronButton from 'components/base/ChevronButton';
import Popover from 'components/base/Popover';

import { useNftFilters } from 'hooks/filters/use-nft-filters';
import { ArtworkCollectionFilterItem } from 'hooks/use-artwork-collection-filter';

import { UnsafeJsonBlob } from 'types/utils';

import CollectionMultiSelect from './CollectionMultiSelect';

interface CollectionFiltersProps {
  collections: ArtworkCollectionFilterItem[];
  publicKey: string;
  counts: UnsafeJsonBlob;
}

export function CollectionFilters(props: CollectionFiltersProps) {
  const { collections, publicKey, counts } = props;
  const [isVisible, toggleVisible] = useToggle(false);

  const { filters } = useNftFilters(publicKey);

  const collectionFilterValues = Object.values(filters.collections);
  const appliedCollectionFilters = collectionFilterValues.filter((v) => v);
  const appliedCollectionFiltersCount = appliedCollectionFilters.length;

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <ChevronButton
          css={{ flexGrow: 0 }}
          size={0}
          onClick={() => {
            toggleVisible();
          }}
        >
          Collections
          {!isVisible && (
            <ButtonFilterIndicator
              applied={appliedCollectionFiltersCount > 0}
            />
          )}
        </ChevronButton>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content>
          <Box
            css={{
              padding: '$3',
              '@bp1': { maxWidth: '680px', minWidth: '340px' },
            }}
          >
            <CollectionMultiSelect
              collections={collections}
              publicKey={publicKey}
              counts={counts}
            />
          </Box>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
