import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import {
  useAccountStats,
  ApiAccountStatsVariables,
} from 'gql/api/queries/account-stats.generated';
import { getQueryDataSetterForQueryKey } from 'hooks/queries/shared';

import { ProfileTabCounts } from 'types/profile';

//
export const getProfileTabCounts = async (
  variables: ApiAccountStatsVariables
): Promise<ProfileTabCounts> => {
  const getProfileCounts = useAccountStats.fetcher(variables);
  const response = await getProfileCounts();

  const accountStats = response.accountStats;

  return {
    collections: accountStats.collectionsCount,
    created: accountStats.createdCount,
    owned: accountStats.collectedCount,
    worlds: accountStats.worldsCount,
    splits: accountStats.splitsCount,
    hidden: accountStats.hiddenCount,
  };
};

const useProfileTabCounts = (
  variables: ApiAccountStatsVariables,
  options: Pick<UseQueryOptions<ProfileTabCounts, Error>, 'initialData'> = {}
) =>
  useQuery({
    ...options,
    queryKey: useProfileTabCounts.getKey(variables),
    queryFn: () => getProfileTabCounts(variables),
  });

useProfileTabCounts.getKey = (variables: ApiAccountStatsVariables) => [
  'ProfileTabCounts',
  variables,
];

useProfileTabCounts.getDataSetter = (variables: ApiAccountStatsVariables) =>
  getQueryDataSetterForQueryKey<ProfileTabCounts>(
    useAccountStats.getKey(variables)
  );

export default useProfileTabCounts;
