import ArtworkCard from 'components/cards/artwork/ArtworkCard';
import ArtworkCardSkeleton from 'components/cards/artwork/ArtworkCardSkeleton';
import { useHomeTabCurationOption } from 'components/home-tab';
import HomeTab from 'components/home-tab/HomeTab';
import HomeTabOnboarding from 'components/home-tab/HomeTabOnboarding';

import { useHomeTabOnboardingNft } from 'gql/hasura/queries/home-tab-onboarding-nft.generated';
import useAssetFallbackByArtworkIds from 'hooks/queries/hasura/artworks/use-asset-fallback-by-artwork-ids';
import { findFallbackAsset } from 'utils/assets';

import { HomeTabCurationProps } from 'types/HomeTab';
import { SetProfileTab } from 'types/profile';

import { ProfileTabsProps } from './types';

type ProfileHomeTabProps = HomeTabCurationProps &
  Omit<ProfileTabsProps, 'indexedStates'> & {
    setProfileTab: SetProfileTab;
  };

export default function ProfileHomeTab(props: ProfileHomeTabProps) {
  const { canCurateHomeTab, homeTabCuration, publicKey, setProfileTab } = props;

  return (
    <HomeTab
      canCurateHomeTab={canCurateHomeTab}
      homeTabCuration={homeTabCuration}
      onboarding={
        <ProfileHomeTabOnboarding
          publicKey={publicKey}
          homeTabCuration={homeTabCuration}
          setProfileTab={setProfileTab}
        />
      }
      queryVariables={{
        type: 'PROFILE',
        accountAddress: publicKey,
      }}
    />
  );
}

type ProfileHomeTabOnboardingProps = {
  homeTabCuration: HomeTabCurationProps['homeTabCuration'];
  publicKey: string;
  setProfileTab: SetProfileTab;
};

function ProfileHomeTabOnboarding(props: ProfileHomeTabOnboardingProps) {
  const { homeTabCuration, publicKey, setProfileTab } = props;

  const homeTabOnboardingQuery = useHomeTabOnboardingNft(
    { publicKey },
    {
      select: (data) => {
        if (!data) return;
        return data.nft[0];
      },
    }
  );

  const nft = homeTabOnboardingQuery.data;

  const id = nft ? nft.id : '';
  const fallbackArtworkId = nft?.assetStatus === 'FAILED' ? id : undefined;
  const getStartedTab = nft?.publicKey === publicKey ? 'created' : 'owned';

  const fallbackAssetsQuery = useAssetFallbackByArtworkIds(
    { artworkIds: fallbackArtworkId as string },
    { enabled: Boolean(fallbackArtworkId) }
  );

  const fallbackAsset = nft
    ? findFallbackAsset(fallbackAssetsQuery.data?.asset, nft.id)
    : undefined;

  const homeTabOption = useHomeTabCurationOption({
    homeTabCuration,
    canCurateHomeTab: true,
    nftId: id,
    type: 'profile',
    showViewHomeTabButton: false,
  });

  return (
    <HomeTabOnboarding
      heading="Your new Home tab"
      subheading={
        <HomeTabOnboarding.Subheading>
          Your Home tab allows you to feature your favorite NFTs. Simply click{' '}
          <HomeTabOnboarding.MenuIndicator /> on any NFT you’ve created or own.
        </HomeTabOnboarding.Subheading>
      }
      onGetStarted={() => setProfileTab(getStartedTab)}
      nftCard={
        nft ? (
          <ArtworkCard
            artwork={nft}
            // @ts-expect-error null-checks
            creator={nft.creator}
            fallbackAsset={fallbackAsset}
            menuOptions={homeTabOption ? [homeTabOption] : []}
          />
        ) : (
          <ArtworkCardSkeleton />
        )
      }
    />
  );
}
