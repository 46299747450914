import {
  EthereumIcon,
  InstagramIcon,
  TwitterIcon,
  VerifiedIcon,
} from '@f8n/icons';
import { onGrid } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import { useRouter } from 'next/router';
import React from 'react';

import Box from 'components/base/Box';
import ChevronButton from 'components/base/ChevronButton';
import CopyToClipboard from 'components/base/CopyToClipboard';
import DropdownMenu, {
  DropdownItemTypeProps,
} from 'components/base/DropdownMenu';
import { IconEl } from 'components/base/IconV2';
import Mono from 'components/base/Mono';
import Text from 'components/base/Text';
import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import EnsIcon from 'assets/icons/ens-icon.svg';
import { UserProfileFragment } from 'gql/hasura/hasura-fragments.generated';
import { truncateStringCenter } from 'utils/helpers';
import { ROUTER_PARAMS } from 'utils/router-params';
import { getIcon, getIdentityMenuLinks } from 'utils/social-links';
import { getSocialHandle } from 'utils/strings';
import { buildSocialLink, buildTikTokHandle, getUrlHost } from 'utils/urls';
import { areKeysEqual } from 'utils/users';

import { SocialLink } from 'types/SocialLink';

interface IdentityMenuProps {
  ens: string | null;
  publicKey: string;
  user: UserProfileFragment | null;
}

function IdentityMenuBase(props: IdentityMenuProps) {
  const { ens, publicKey, user } = props;
  const socialLinks = getIdentityMenuLinks(user);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <ChevronButton variant="outline" size={0}>
          {ens || (
            <Mono size={1} css={{ whiteSpace: 'nowrap' }}>
              {truncateStringCenter(4, publicKey)}
            </Mono>
          )}
        </ChevronButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content sideOffset={onGrid(3)} minWidth={330}>
          {ens && (
            <IdentityMenuItem
              action={<CopyToClipboard textToCopy={ens} />}
              icon={EnsIcon}
              // @ts-expect-error null-checks
              href={buildSocialLink('ens', publicKey)}
              text={<Text weight="semibold">{ens}</Text>}
              type="external-link"
            />
          )}
          <IdentityMenuItem
            action={<CopyToClipboard textToCopy={publicKey} />}
            icon={EthereumIcon}
            // @ts-expect-error null-checks
            href={buildSocialLink('eth', publicKey)}
            text={<Mono>{truncateStringCenter(4, publicKey)}</Mono>}
            type="external-link"
          />
          {user && (
            <IdentityMenuVerificationItems publicKey={publicKey} user={user} />
          )}
          {socialLinks.length > 0 && <DropdownMenu.Divider />}
          {socialLinks.map((link) => {
            return (
              <IdentityMenuItem
                key={link.handle}
                // @ts-expect-error null-checks
                href={buildSocialLink(link.platform, link.handle)}
                icon={getIcon(link.platform)}
                text={getExternalHandle(link)}
                type="external-link"
              />
            );
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

type IdentityMenuVerificationItemsProps = {
  publicKey: string;
  user: UserProfileFragment;
};

function IdentityMenuVerificationItems(
  props: IdentityMenuVerificationItemsProps
) {
  const router = useRouter();
  const { user } = props;
  const twitterHandle = getVerifiedSocialHandle(user.twitSocialVerifs);
  const instaHandle = getVerifiedSocialHandle(user.instaSocialVerifs);

  const auth = useAuth();

  if (hasPublicKey(auth)) {
    const isMyProfile = areKeysEqual([auth.publicKey, user.publicKey]);

    if (isMyProfile) {
      return (
        <>
          <DropdownMenu.Divider />
          {twitterHandle ? (
            <TwitterMenuItem handle={twitterHandle} />
          ) : (
            <IdentityMenuItem
              href={{
                pathname: '/profile/verify/twitter',
                query: { [ROUTER_PARAMS.REDIRECT_PATH]: router.asPath },
              }}
              icon={TwitterIcon}
              text={<VerifyViaText>Verify via Twitter</VerifyViaText>}
              type="link"
            />
          )}
          {instaHandle ? (
            <InstaMenuItem handle={instaHandle} />
          ) : (
            <IdentityMenuItem
              href={{
                pathname: '/profile/verify/instagram',
                query: { [ROUTER_PARAMS.REDIRECT_PATH]: router.asPath },
              }}
              icon={InstagramIcon}
              text={<VerifyViaText>Verify via Instagram</VerifyViaText>}
              type="link"
            />
          )}
        </>
      );
    }
  }

  return (
    <>
      {(twitterHandle || instaHandle) && <DropdownMenu.Divider />}
      {twitterHandle && <TwitterMenuItem handle={twitterHandle} />}
      {instaHandle && <InstaMenuItem handle={instaHandle} />}
    </>
  );
}

function TwitterMenuItem(props: { handle: string }) {
  return (
    <IdentityMenuItem
      // @ts-expect-error null-checks
      href={buildSocialLink('twitter', props.handle)}
      icon={TwitterIcon}
      text={<VerifiedHandleText handle={props.handle} />}
      type="external-link"
    />
  );
}

function InstaMenuItem(props: { handle: string }) {
  return (
    <IdentityMenuItem
      // @ts-expect-error null-checks
      href={buildSocialLink('instagram', props.handle)}
      icon={InstagramIcon}
      text={<VerifiedHandleText handle={props.handle} />}
      type="external-link"
    />
  );
}

function VerifiedHandleText(props: { handle: string }) {
  return (
    <Box css={{ display: 'flex', alignItems: 'center', gap: '$1' }}>
      <span>{props.handle}</span> <VerifiedIcon />
    </Box>
  );
}

function VerifyViaText(props: { children: React.ReactNode }) {
  return <Text weight="semibold">{props.children}</Text>;
}

type IconProps = React.ForwardRefExoticComponent<
  React.ComponentProps<typeof InstagramIcon>
>;

type IdentityMenuItemProps = DropdownItemTypeProps & {
  action?: React.ReactNode;
  text: React.ReactNode;
  icon: IconProps | IconEl;
};

function IdentityMenuItem(props: IdentityMenuItemProps) {
  const { text, action, icon, ...rest } = props;
  const Icon = icon;
  return (
    <Box css={{ position: 'relative', '@bp1': { maxWidth: 340 } }}>
      <DropdownMenu.Item {...rest}>
        <IconContainer>
          <Icon />
        </IconContainer>
        <Text ellipsis>{text}</Text>
      </DropdownMenu.Item>
      <ActionContainer>{action}</ActionContainer>
    </Box>
  );
}

const getVerifiedSocialHandle = (
  userSocialVerificationAttempts:
    | UserProfileFragment['twitSocialVerifs']
    | UserProfileFragment['instaSocialVerifs']
): string | undefined => {
  if (userSocialVerificationAttempts.length === 0) return;

  const verifiedSocialAccount = userSocialVerificationAttempts.find(
    (account) => account.isValid
  );

  if (!verifiedSocialAccount) return;
  if (!verifiedSocialAccount.username) return;

  return verifiedSocialAccount.username;
};

const getExternalHandle = (link: SocialLink): string => {
  switch (link.platform) {
    case 'website': {
      return getUrlHost(link.handle);
    }

    case 'discord': {
      return link.handle;
    }

    case 'tiktok': {
      return getSocialHandle(buildTikTokHandle(link.handle));
    }

    case 'youtube': {
      return 'YouTube';
    }

    default: {
      return getSocialHandle(link.handle);
    }
  }
};

const ActionContainer = styled('div', {
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

const IconContainer = styled('div', {
  // This is mostly done to accomodate the ENS icon, which is wider than our usual
  // 16x16 icon size.
  width: '22px',
  height: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const IdentityMenu = Object.assign(IdentityMenuBase, {
  Item: IdentityMenuItem,
});

export default IdentityMenu;
