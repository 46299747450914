import NextLink from 'next/link';

import WorldCardGrid from 'components/WorldCardGrid';
import Link from 'components/base/Link';
import WorldCard from 'components/cards/worlds/WorldCard';

import useWorldsByCurator from 'hooks/queries/api/use-worlds-by-curator';
import { getPath } from 'utils/router';

import FilterLayout from './FilterLayout';
import { ProfileTabsProps } from './types';

type WorldsTabProps = ProfileTabsProps;

export default function WorldsTab(props: WorldsTabProps) {
  const { publicKey } = props;

  const query = useWorldsByCurator({ accountAddress: publicKey });

  if (query.isLoading) {
    return (
      <FilterLayout filters={null}>
        <WorldCardGrid>
          {[...Array(props.skeletonCardCount)].map((_, index) => (
            <WorldCard.Skeleton key={index} />
          ))}
        </WorldCardGrid>
      </FilterLayout>
    );
  }

  const worlds = query.data;

  if (!worlds) {
    return null;
  }

  return (
    <FilterLayout filters={null}>
      <WorldCardGrid>
        {worlds.map((world) => (
          <NextLink
            passHref
            href={getPath.world.page(world.slug)}
            key={world.slug}
          >
            <Link>
              <WorldCard.Connected
                key={world.slug}
                imageUrl={world.imageUrl ?? undefined}
                name={world.name}
                backgroundImageUrl={world.coverUrl ?? undefined}
                moderationStatus={world.moderationStatus}
                id={world.id}
              />
            </Link>
          </NextLink>
        ))}
      </WorldCardGrid>
    </FilterLayout>
  );
}
