import NextLink from 'next/link';

import Button from 'components/base/Button';
import { isMyAddress } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import FollowButton, { FollowButtonProps } from './FollowButton';

export function ProfileFollowButton(props: FollowButtonProps) {
  const auth = useAuth();

  const isMyProfile = isMyAddress(auth, props.publicKey);

  if (isMyProfile) {
    return (
      <NextLink href="/profile" passHref>
        <Button as="a" variant="outline" size={props.size}>
          Edit profile
        </Button>
      </NextLink>
    );
  }

  return <FollowButton {...props} />;
}
