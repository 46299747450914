import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/exhibitions-by-creator.generated';

export type WorldsByCreator =
  Generated.ApiExhibitionsByCreator['exhibitionsByCreator'];

export async function getWorldsByCreator(
  variables: Generated.ApiExhibitionsByCreatorVariables
): Promise<WorldsByCreator> {
  const fetchWorldCreators =
    Generated.useExhibitionsByCreator.fetcher(variables);
  const response = await fetchWorldCreators();

  return response.exhibitionsByCreator;
}

export default function useWorldsByCreator(
  variables: Generated.ApiExhibitionsByCreatorVariables,
  options: Pick<
    UseQueryOptions<WorldsByCreator, Error>,
    | 'enabled'
    | 'placeholderData'
    | 'refetchOnMount'
    | 'refetchOnReconnect'
    | 'refetchOnWindowFocus'
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: ['world', 'listByCreator', variables],
    queryFn: () => getWorldsByCreator(variables),
  });
}
