import { keepPreviousData, useQuery } from '@tanstack/react-query';

import {
  FacetEnrichmentCreatedArtworkCollections,
  useInfiniteFacetEnrichmentCreatedArtworkCollections,
} from 'gql/hasura/queries/facet-enrichment-created-artwork-collections.generated';
import { ALGOLIA_COLLECTION_FACET_LIMIT_PER_PAGE } from 'lib/algolia';
import { hasuraPaginator } from 'utils/react-query';

import { AlgoliaArtwork } from 'types/Algolia';

import { getAlgoliaSearchResults } from './queries/algolia/shared';
import { useInfiniteQueryItems } from './use-infinite-query-items';

export type CollectionFacetEnrichment =
  FacetEnrichmentCreatedArtworkCollections['items'][number];

export type ArtworkCollectionFilterItem = CollectionFacetEnrichment;

interface CollectionCreatorFilterItemsApi {
  collections: ArtworkCollectionFilterItem[];
}

export default function useArtworkCollectionFilter(
  publicKey: string
): CollectionCreatorFilterItemsApi {
  // Get a baseline set of collection facets.
  // Here we are intentionally not filtering by market availability.
  const { data: collectionFacets } = useQuery({
    queryKey: ['AlgoliaArtworkCollectionFacets', publicKey],
    queryFn: async () => {
      return getAlgoliaSearchResults<AlgoliaArtwork>({
        index: 'artworks_sort_default',
        query: '',
        options: {
          facets: ['collection.contractAddress'],
          facetFilters: [
            'isDeleted:false',
            'isHidden:false',
            `creator.publicKey:${publicKey}`,
          ],
          maxValuesPerFacet: ALGOLIA_COLLECTION_FACET_LIMIT_PER_PAGE,
          hitsPerPage: 0,
        },
      }).then((data) => {
        return data?.facets?.['collection.contractAddress'] || {};
      });
    },
    enabled: publicKey !== undefined && publicKey !== '',
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });

  // Get an array of contractAddresses from the facets
  const contractAddresses = collectionFacets
    ? Object.keys(collectionFacets)
    : [];

  const facetEnrichmentCreatedArtworkCollectionsQuery =
    useInfiniteFacetEnrichmentCreatedArtworkCollections(
      {
        publicKey,
        contractAddresses,
        ...hasuraPaginator.initialPageParam,
      },
      {
        initialPageParam: hasuraPaginator.initialPageParam,
        getNextPageParam: hasuraPaginator.getNextPageParam,
        placeholderData: keepPreviousData,
      }
    );

  // Flatten the enrichment data into an array of collections
  const collectionItems = useInfiniteQueryItems(
    facetEnrichmentCreatedArtworkCollectionsQuery,
    'contractAddress'
  );

  // Transfrom array of creators into a map, with their publicKey as an index
  const collectionContractAddressMap = collectionItems.reduce(
    (prev, curr) => {
      prev[curr.contractAddress] = curr;
      return prev;
    },
    {} as Record<string, ArtworkCollectionFilterItem>
  );

  if (!collectionFacets) {
    return { collections: [] };
  }

  const colletionCreatorFilterItems = Object.entries(collectionFacets)
    .reduce((prev, curr): ArtworkCollectionFilterItem[] => {
      const [contractAddress, count] = curr;
      const creator = collectionContractAddressMap[contractAddress];

      // Since we're fetching user data from two sources (algolia + hasura), there's
      // a chance that we'll sometimes fail to fetch a user from hasura. In that case,
      // silently filter it out here.
      if (!creator || count === undefined) {
        return prev;
      }

      return prev.concat(creator);
    }, [] as ArtworkCollectionFilterItem[])
    .sort((a, b) => a.name.localeCompare(b.name));

  return {
    collections: colletionCreatorFilterItems,
  };
}
