import { sizes } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import Tippy from '@tippyjs/react/headless';
import NextLink from 'next/link';
import { forwardRef } from 'react';

import { AvatarVariants } from 'components/base/Avatar';
import WorldLogo from 'components/base/WorldLogo';

import { AVATAR_BORDERS, AVATAR_RADII } from 'utils/avatar';
import { getPath } from 'utils/router';

import { WorldOverview } from 'types/World';
import { SingletonPopoverObject } from 'types/popover';

interface WorldStackInteractiveProps {
  worlds: WorldOverview[];
  target: SingletonPopoverObject;
}

export default function WorldStackInteractive(
  props: WorldStackInteractiveProps
) {
  const { worlds, target } = props;

  return (
    <Stack size={2}>
      {worlds.map((world) => (
        <Tippy key={world.id} content={world.slug} singleton={target}>
          <WorldStackElement world={world} key={world.id} size={2} />
        </Tippy>
      ))}
    </Stack>
  );
}

interface WorldStackElementProps {
  world: WorldOverview;
  size?: AvatarVariants['size'];
}

const WorldStackElement = forwardRef(function WorldStackElement(
  props: WorldStackElementProps,
  ref: React.Ref<HTMLAnchorElement> | undefined
) {
  const { world, size } = props;

  return (
    <NextLink href={getPath.world.page(world.slug)} passHref>
      <WorldLogoWrapper
        aria-label={world.name}
        variant="raised"
        size={size}
        ref={ref}
      >
        <WorldLogo key={world.id} imageUrl={world.imageUrl ?? ''} size={size} />
      </WorldLogoWrapper>
    </NextLink>
  );
});

const WorldLogoWrapper = styled('a', {
  position: 'relative',
  zIndex: 2,
  variants: {
    variant: {
      // This is added here to simplify the typing of the parent
      base: {},
      raised: {
        boxShadow: '$soft1',
        backgroundColor: '$white100',
      },
      white: {
        backgroundColor: '$white100',
      },
    },
    size: {
      0: {
        padding: `${AVATAR_BORDERS[0]}px`,
        borderRadius: `${AVATAR_BORDERS[0] + AVATAR_RADII[0]}px`,
      },
      1: {
        padding: `${AVATAR_BORDERS[1]}px`,
        borderRadius: `${AVATAR_BORDERS[1] + AVATAR_RADII[1]}px`,
      },
      2: {
        padding: `${AVATAR_BORDERS[2]}px`,
        borderRadius: `${AVATAR_BORDERS[1] + AVATAR_RADII[1]}px`,
      },
      3: {
        padding: `${AVATAR_BORDERS[3]}px`,
        borderRadius: `${AVATAR_BORDERS[3] + AVATAR_RADII[3]}px`,
      },
      4: {
        padding: `${AVATAR_BORDERS[4]}px`,
        borderRadius: `${AVATAR_BORDERS[4] + AVATAR_RADII[4]}px`,
      },
      5: {
        padding: `${AVATAR_BORDERS[5]}px`,
        borderRadius: `${AVATAR_BORDERS[5] + AVATAR_RADII[5]}px`,
      },
      6: {
        padding: `${AVATAR_BORDERS[6]}px`,
        borderRadius: `${AVATAR_BORDERS[6] + AVATAR_RADII[6]}px`,
      },
      7: {
        padding: `${AVATAR_BORDERS[7]}px`,
        borderRadius: `${AVATAR_BORDERS[7] + AVATAR_RADII[7]}px`,
      },
      8: {
        padding: `${AVATAR_BORDERS[8]}px`,
        borderRadius: `${AVATAR_BORDERS[8] + AVATAR_RADII[8]}px`,
      },
      9: {
        padding: `${AVATAR_BORDERS[9]}px`,
        borderRadius: `${AVATAR_BORDERS[9] + AVATAR_RADII[9]}px`,
      },
    },
  },
  defaultVariants: {
    variant: 'base',
  },
});

/**
 * this value will give us a 35% overlap on avatars in the stack
 */
const OVERLAP_MULTIPLIER = 0.35;

const Stack = styled('div', {
  display: 'flex',
  variants: {
    size: {
      0: {
        [`${WorldLogoWrapper}:not(:first-child)`]: {
          /**
           * equals -16 * 0.35
           */
          marginLeft: -sizes.avatars[0] * OVERLAP_MULTIPLIER,
        },
      },
      1: {
        [`${WorldLogoWrapper}:not(:first-child)`]: {
          marginLeft: -sizes.avatars[1] * OVERLAP_MULTIPLIER,
        },
      },
      2: {
        [`${WorldLogoWrapper}:not(:first-child)`]: {
          marginLeft: -sizes.avatars[2] * OVERLAP_MULTIPLIER,
        },
      },
      3: {
        [`${WorldLogoWrapper}:not(:first-child)`]: {
          marginLeft: -sizes.avatars[3] * OVERLAP_MULTIPLIER,
        },
      },
      4: {},
      5: {},
      6: {},
      7: {},
      8: {},
      9: {},
    },
  },
});
