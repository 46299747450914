import { AdminIcon } from '@f8n/icons';

import DropdownMenu, { DropdownItemProps } from 'components/base/DropdownMenu';
import MeatballDropdown from 'components/dropdown/MeatballDropdown';
import { hasUser, isMyAddress } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import NotAllowedIcon from 'assets/icons/not-allowed.svg';
import useModal from 'hooks/use-modal';

interface ProfileMenuProps {
  publicKey: string;
}

export default function ProfileMenu(props: ProfileMenuProps) {
  const { publicKey } = props;
  const { setModal } = useModal();
  const auth = useAuth();

  if (!hasUser(auth)) {
    return null;
  }

  const isMyProfile = isMyAddress(auth, publicKey);

  const options: DropdownItemProps[] = [];

  if (!isMyProfile) {
    options.push({
      children: (
        <>
          <NotAllowedIcon />
          Report
        </>
      ),
      onClick: () => setModal({ type: 'REPORT' }),
      variant: 'danger',
      type: 'button',
    });
  }

  if (auth.user.isAdmin) {
    options.unshift(
      {
        children: (
          <>
            <AdminIcon />
            Edit Profile
          </>
        ),
        href: `/profile?user=${publicKey}`,
        type: 'link',
      },
      {
        children: (
          <>
            <AdminIcon />
            Admin Tools
          </>
        ),
        onClick: () => {
          return setModal({ type: 'ADMIN_TOOLS' });
        },
        type: 'button',
      }
    );
  }

  if (options.length === 0) {
    return null;
  }

  return (
    <MeatballDropdown
      align="center"
      side="bottom"
      buttonSize={0}
      buttonVariant="blur"
    >
      {options.map((option, index) => {
        return <DropdownMenu.Item key={index} {...option} />;
      })}
    </MeatballDropdown>
  );
}
