import CardGrid from 'components/CardGrid';

import CollectionCard from './CollectionCard';

interface CollectionCardSkeletonGridProps {
  items?: number;
}

export default function CollectionCardSkeletonGrid(
  props: CollectionCardSkeletonGridProps
) {
  const { items = 3 } = props;

  return (
    <CardGrid.Root>
      {[...Array(Math.ceil(Math.min(items, 12)))].map((_, index) => (
        <CollectionCard.Skeleton key={index} />
      ))}
    </CardGrid.Root>
  );
}
