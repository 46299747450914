import { cond, equals, always } from 'ramda';

import Page from 'components/Page';

import { ModerationStatus } from 'types/Moderation';

import WarningBlock from './WarningBlock';
import WarningTermsLink from './WarningTermsLink';

interface ProfileWarningBlockProps {
  moderationStatus: ModerationStatus;
}

export default function ProfileWarningBlock(props: ProfileWarningBlockProps) {
  const { moderationStatus } = props;

  return cond<ModerationStatus, JSX.Element>([
    [
      (status) => equals('SUSPENDED', status),
      always(
        <Page title="Profile Suspended">
          <ProfileSuspended />
        </Page>
      ),
    ],
    [
      (status) => equals('UNDER_REVIEW', status),
      always(
        <Page title="Profile Under Review">
          <ProfileUnderReview />
        </Page>
      ),
    ],
  ])(moderationStatus);
}

function ProfileSuspended() {
  return (
    <WarningBlock
      title="This profile has been permanently removed."
      description={
        <>
          This profile was found to be in violation of the Foundation{' '}
          <WarningTermsLink /> and has been permanently removed.
        </>
      }
      status="SUSPENDED"
    />
  );
}

function ProfileUnderReview() {
  return (
    <WarningBlock
      title="This profile is under review."
      description={
        <>
          This profile is currently under review by the Foundation team to
          ensure it has not violated the Foundation <WarningTermsLink />.
        </>
      }
      status="UNDER_REVIEW"
    />
  );
}
