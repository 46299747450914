import { onGrid } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import React from 'react';

import Stat from 'components/Stat';
import Box from 'components/base/Box';
import Divider from 'components/base/Divider';
import Flex from 'components/base/Flex';
import MutualFollowers from 'components/follows/MutualFollowers';
import { hasPublicKey, isMyAddress } from 'contexts/auth/helpers';
import { Auth } from 'contexts/auth/types';

import useUserMutualFollows from 'hooks/use-user-mutual-follows';
import { abbreviateValue } from 'utils/formatters';

type BaseFollowCountsProps = {
  followers: number;
  following: number;
  onFollowersClick(): void;
  onFollowingClick(): void;
  auth: Auth;
  publicKey: string;
};

type ProfileCounts = BaseFollowCountsProps;

export default function ProfileCounts(props: ProfileCounts) {
  const {
    followers,
    following,
    onFollowersClick,
    onFollowingClick,
    auth,
    publicKey,
  } = props;

  const hasFollowers = followers > 0;
  const currentUserPublicKey = hasPublicKey(auth) ? auth.publicKey : '';

  const { data: mutualFollows, isLoading } = useUserMutualFollows({
    publicKey,
    currentUserPublicKey,
    limit: 5,
    offset: 0,
  });

  const displayMutualFollowers =
    !isMyAddress(auth, publicKey) &&
    hasFollowers &&
    mutualFollows &&
    mutualFollows.count !== 0;

  return (
    <FollowCountsContainer>
      <FollowCountsItem onClick={onFollowingClick}>
        <Stat.Label as="label">Following</Stat.Label>
        <FollowCountsCount>{abbreviateValue(following)}</FollowCountsCount>
      </FollowCountsItem>
      <FlexibleDivider>
        <FollowDivider />
      </FlexibleDivider>
      <FollowCountsItem onClick={onFollowersClick}>
        <Stat.Label as="label">Followers</Stat.Label>
        <FollowCountsCount>{abbreviateValue(followers)}</FollowCountsCount>
      </FollowCountsItem>
      {displayMutualFollowers && (
        <>
          <FlexibleDivider>
            <FollowDivider />
          </FlexibleDivider>
          <FollowCountsItem
            /**
             * this fixes a minor layout shift because the component
             * is 4px taller than its siblings
             */
            css={{ marginBottom: -4 }}
          >
            <Stat.Label
              as="label"
              css={{ whiteSpace: 'nowrap' }}
              onClick={onFollowersClick}
            >
              Followed by
            </Stat.Label>
            <Box
              css={{
                // Aligns with bottom of following/followers counts
                marginTop: -4,
                '@bp2': {
                  marginTop: -2,
                },
              }}
            >
              <Flex>
                <MutualFollowers
                  mutualFollows={mutualFollows}
                  isLoading={isLoading}
                  key={publicKey}
                  publicKey={publicKey}
                  currentUserPublicKey={currentUserPublicKey}
                  otherFollowersVariant="SHOW_MODAL"
                  onOtherFollowersClick={onFollowingClick}
                  variant="BADGE"
                  hasPopovers
                  size={1}
                />
              </Flex>
            </Box>
          </FollowCountsItem>
        </>
      )}
    </FollowCountsContainer>
  );
}

const FlexibleDivider = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  flexBasis: onGrid(10),
  flexShrink: 1,
  flexGrow: 0,
  minWidth: onGrid(6),

  '@bp2': {
    flexBasis: onGrid(12),
    minWidth: onGrid(8),
  },
});

const FollowDivider = styled(Divider, {
  height: '100%',
  width: 1,
});

const FollowCountsContainer = styled('div', {
  display: 'flex',

  label: {
    fontSize: '$0',
    cursor: 'pointer',
  },

  '@bp2': {
    label: {
      fontSize: 'inherit',
    },
  },
});

const FollowCountsItem = styled('button', {
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  background: 'transparent',
  border: 'none',
  textAlign: 'left',
  cursor: 'pointer',
  borderRadius: '$0',
  lineHeight: '$0',
  fontFamily: '$body',
  gap: '$2',
  fontSize: '$1',
  flexShrink: 1,

  color: '$black70',
  '@hover': {
    '&:hover': {
      color: '$black100',
    },
  },

  '&:focus-visible': {
    color: '$black100',
    borderRadius: '$1',
    outline: '4px solid $black30',
    outlineOffset: onGrid(1),
  },
});

function FollowCountsCount(props: { children: React.ReactNode }) {
  return (
    <Stat.Value size={{ '@initial': 2, '@bp2': 4 }}>
      {props.children}
    </Stat.Value>
  );
}
