import HeadlessTippy from '@tippyjs/react/headless';

import WorldCard from 'components/cards/worlds/WorldCard';
import SingletonPopoverContainer from 'components/popover/SingletonPopoverContainer';

import useWorldBySlug from 'hooks/queries/use-world-by-slug';
import usePopoverContainerRef from 'hooks/use-popover-container-ref';
import { DEFAULT_SINGLETON_TIPPY_PROPS } from 'utils/popover';

import { PopoverPlacement, SingletonPopoverObject } from 'types/popover';

interface WorldHoverCardSingletonProps {
  placement: PopoverPlacement;
  source: SingletonPopoverObject;
}

export function WorldHoverCardSingleton(props: WorldHoverCardSingletonProps) {
  const { source, placement } = props;
  const popoverContainerRef = usePopoverContainerRef();

  return (
    <HeadlessTippy
      {...DEFAULT_SINGLETON_TIPPY_PROPS}
      singleton={source}
      render={(attributes, content: string) => {
        if (!content) {
          return null;
        }

        return (
          <SingletonPopoverContainer {...attributes}>
            <WorldHoverCardWrapper
              key={`world-popover-${content}`}
              slug={content}
            />
          </SingletonPopoverContainer>
        );
      }}
      appendTo={popoverContainerRef.current}
      interactive
      offset={[0, 16]}
      placement={placement}
      touch={false}
    />
  );
}

interface WorldHoverCardWrapperProps {
  slug: string;
}

function WorldHoverCardWrapper(props: WorldHoverCardWrapperProps) {
  const { slug } = props;
  const worldQuery = useWorldBySlug(
    {
      slug,
    },
    {
      retry: false,
    }
  );

  const world = worldQuery.data;

  if (!world) return null;

  return (
    <WorldCard
      imageUrl={world.imageUrl ?? undefined}
      name={world.name}
      backgroundImageUrl={world.coverUrl ?? undefined}
      moderationStatus={world.moderationStatus}
      size="short"
    />
  );
}
