import { styled } from '@f8n-frontend/stitches';
import { useQueryClient } from '@tanstack/react-query';
import {
  GetStaticPathsResult,
  GetStaticPropsContext,
  GetStaticPropsResult,
} from 'next';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import Empty from 'components/Empty';
import Page from 'components/Page';
import ModerationBanner from 'components/admin/ModerationBanner';
import Body from 'components/base/Body';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Divider from 'components/base/Divider';
import Flex from 'components/base/Flex';
import { H1Heading } from 'components/base/Heading';
import MarkdownText from 'components/base/MarkdownText';
import Tabs from 'components/base/Tabs';
import { ProfileFollowButton } from 'components/follows/ProfileFollowButton';
import ProfileRelatedWalletsModal, {
  ProfileRelatedWalletsModalTab,
  useProfileRelatedWalletsModal,
} from 'components/modals/ProfileRelatedWalletsModal';
import ReportModal from 'components/modals/ReportModal';
import AdminToolsModal from 'components/modals/admin-tools/AdminToolsModal';
import BlockFollowsPane from 'components/modals/admin-tools/BlockFollowsPane';
import ChangeStatusPane from 'components/modals/admin-tools/ChangeStatusPane';
import RemoveWeb2DataPane from 'components/modals/admin-tools/RemoveWeb2DataPane';
import SetFeatureFlagsPane from 'components/modals/admin-tools/SetFeatureFlagsPane';
import EmailCaptureBlock from 'components/profiles/EmailCaptureBlock';
import IdentityMenu from 'components/profiles/IdentityMenu';
import ProfileCollectors from 'components/profiles/ProfileCollectors';
import ProfileCounts from 'components/profiles/ProfileCounts';
import ProfileCoverImage from 'components/profiles/ProfileCoverImage';
import ProfileMenu from 'components/profiles/ProfileMenu';
import ProfileWorlds from 'components/profiles/ProfileWorlds';
import CollectionsTab from 'components/profiles/tabs/CollectionsTab';
import CreatedTab from 'components/profiles/tabs/CreatedTab';
import HiddenTab from 'components/profiles/tabs/HiddenTab';
import OwnedTab from 'components/profiles/tabs/OwnedTab';
import ProfileHomeTab from 'components/profiles/tabs/ProfileHomeTab';
import SplitsTab from 'components/profiles/tabs/SplitsTab';
import WorldsTab from 'components/profiles/tabs/WorldsTab';
import ProfileWarningBlock from 'components/trust-safety/ProfileWarningBlock';
import { hasPublicKey, isAdmin, isMyAddress } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import { PRODUCT_BRANDING } from 'copy/branding';

import Home from 'assets/icons/home.svg';
import { UserProfileByPublicKey } from 'gql/hasura/queries/user-profile-by-public-key.generated';
import useWorldsByCreator, {
  WorldsByCreator,
  getWorldsByCreator,
} from 'hooks/queries/api/use-worlds-by-creator';
import { getENSNameByPublicKey } from 'hooks/queries/ens/use-ens-names';
import useProfileTabCounts, {
  getProfileTabCounts,
} from 'hooks/queries/hasura/profile/use-profile-tab-counts';
import useUserProfile, {
  getUserProfile,
} from 'hooks/queries/hasura/users/use-user-profile';
import useHomeTabArtworks from 'hooks/use-home-tab-artworks';
import { getHomeTabItems, useHomeTabItems } from 'hooks/use-home-tab-items';
import { useIsHydrated } from 'hooks/use-is-hydrated';
import { NOT_FOUND, REVALIDATE_TIME } from 'lib/constants';
import { setProfileModerationProxy } from 'queries/admin/profile';
import { getUserPublicKeyByUsername } from 'queries/api/users';
import { getPromiseResult } from 'queries/utils';
import { addressValueSchema } from 'schemas/shared';
import { profileUrlParams } from 'schemas/url/profile';
import { getAggregateCount } from 'utils/aggregate';
import { abbreviateValue } from 'utils/formatters';
import {
  formatUsername,
  getArrayOrEmpty,
  truncateMetaDescription,
} from 'utils/helpers';
import {
  deleteItemByIdFromHomeTabItems,
  getHomeTabNftIdMap,
} from 'utils/home-tab/utils';
import { optimizeAsset, optimizeMetaImage } from 'utils/imgix';
import { isFlaggedForModeration } from 'utils/moderation';
import { ROUTER_PARAMS } from 'utils/router-params';
import { getProfilePageTitle } from 'utils/users';

import { UserLight } from 'types/Account';
import { HomeTabCurationProps, HomeTabItem } from 'types/HomeTab';
import { ProfileTabCounts, TabValue } from 'types/profile';
import { UnsafeAny } from 'types/utils';

type ProfileUser = NonNullable<UserProfileByPublicKey['user']>;

type BaseProfileProps = {
  ens: string | null;
  publicKey: string;
};

type ProfilePageProps = BaseProfileProps &
  (
    | { type: 'ANON' }
    | {
        type: 'PROFILE';
        user: ProfileUser;
        counts: ProfileTabCounts & {
          collectors: number;
          followers: number;
          following: number;
        };
        homeTabItems: HomeTabItem[];
        worldsCuratedIn: WorldsByCreator;
      }
  );

export default function ProfilePage(props: ProfilePageProps) {
  switch (props.type) {
    case 'ANON':
      return <AnonProfile key={props.publicKey} {...props} />;
    case 'PROFILE':
      return <Profile key={props.publicKey} {...props} />;
  }
}

function AnonProfile(props: Extract<ProfilePageProps, { type: 'ANON' }>) {
  const { ens, publicKey } = props;

  return (
    <Page absolute headerMode="DARK">
      <ProfileCoverImage
        avatar={null}
        coverImage={null}
        publicKey={publicKey}
      />
      <Body css={{ flexGrow: 0, marginBottom: '$8' }}>
        <Box>
          <IdentityMenu ens={ens} user={null} publicKey={publicKey} />
        </Box>
      </Body>
      <EmptyStateContainer />
    </Page>
  );
}

function Profile(props: Extract<ProfilePageProps, { type: 'PROFILE' }>) {
  const {
    counts: initialCounts,
    ens,
    homeTabItems,
    publicKey,
    user: initialUser,
    worldsCuratedIn: initialWorldsCuratedIn,
  } = props;

  const queryClient = useQueryClient();

  const auth = useAuth();
  const isCurrentUserAdmin = isAdmin(auth);

  const isMyProfile = isMyAddress(auth, publicKey);

  /** Allow admins, and users viewing their own profile, to see "un-indexed" artworks */
  const canViewUnindexedArtwork = isCurrentUserAdmin || isMyProfile;
  const indexedStates = canViewUnindexedArtwork ? [true, false] : [true];

  const userProfileQuery = useUserProfile(
    { publicKey },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      placeholderData: initialUser,
    }
  );
  const setUserProfile = useUserProfile.getDataSetter({ publicKey });

  const user = userProfileQuery.data || initialUser;
  const followers = getAggregateCount(user.followerCount);
  const following = getAggregateCount(user.followingCount);

  const profileTabCountsQuery = useProfileTabCounts(
    { publicKey },
    { initialData: initialCounts }
  );
  const counts = profileTabCountsQuery.data || initialCounts;

  const tabs = useProfileTabs({ counts, homeTabItems });
  const router = useRouter();

  const showModalTab = (tab: ProfileRelatedWalletsModalTab) => {
    dispatchRelatedWallets({ type: 'show', tab });
  };

  const [relatedWalletsState, dispatchRelatedWallets] =
    useProfileRelatedWalletsModal('collectors');

  useEffect(() => {
    if (!router.isReady) return;

    const deepLink = router.query[ROUTER_PARAMS.MODAL_DEEP_LINK];
    if (!deepLink) return;

    if (deepLink === 'followers') {
      showModalTab('followers');
    }

    if (deepLink === 'following') {
      showModalTab('following');
    }
  }, [router.isReady]);

  const coverImageUrl = user.coverImageUrl;

  const avatarUrl = user.profileImageUrl;

  let openGraphImageUrl: string | undefined = undefined;

  if (coverImageUrl) {
    openGraphImageUrl = optimizeMetaImage(coverImageUrl);
  } else if (avatarUrl) {
    openGraphImageUrl = optimizeMetaImage(avatarUrl);
  }

  const homeTabQuery = useHomeTabItems(
    { accountAddress: publicKey, type: 'PROFILE' },
    {
      placeholderData: homeTabItems,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const hasHomeTabItems = homeTabQuery.data && homeTabQuery.data.length > 0;
  const hasHomeTab = isMyProfile || hasHomeTabItems;
  const moderationStatus = user?.moderationStatus;
  const isOwnerOrAdmin = isCurrentUserAdmin || isMyProfile;
  const isModerated = isFlaggedForModeration(moderationStatus);
  const showFullScreenModerationBlock =
    isModerated && !isCurrentUserAdmin && !isMyProfile;
  const showModerationBanner =
    isModerated && (isCurrentUserAdmin || isMyProfile);

  const homeTabNftIdMap = getHomeTabNftIdMap(homeTabQuery.data);

  const createdArtworks = getArrayOrEmpty(user.ownedArtworks);
  const userCollectors = createdArtworks
    .map((artwork) => artwork?.owner)
    .filter(Boolean) as UserLight[];

  const setHomeTabItemsQueryCache = useHomeTabItems.getDataSetter({
    accountAddress: publicKey,
    type: 'PROFILE',
  });

  const setHomeTabArtworksQueryCache = useHomeTabArtworks.getDataSetter({
    uid: publicKey,
  });

  const homeTabCuration: HomeTabCurationProps['homeTabCuration'] = {
    onAddToHomeTab: () => {
      queryClient
        .invalidateQueries({
          queryKey: useHomeTabItems.getKey({
            accountAddress: publicKey,
            type: 'PROFILE',
          }),
        })
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: useHomeTabArtworks.getKey({ uid: publicKey }),
          });
        });
    },
    onRemoveFromHomeTab: (itemId) => {
      setHomeTabItemsQueryCache((items) => {
        return deleteItemByIdFromHomeTabItems(itemId, items);
      });

      setHomeTabArtworksQueryCache((items) => {
        return deleteItemByIdFromHomeTabItems(itemId, items);
      });
    },
    isOnHomeTab: (nftId) => {
      return Boolean(homeTabNftIdMap[nftId]);
    },
    getHomeTabItemId: (nftId) => {
      return homeTabNftIdMap[nftId] as string;
    },
    viewHomeTab: () => {
      tabs.setValue('home');
    },
  };

  const worldsByCreatorQuery = useWorldsByCreator(
    {
      publicKey,
      perPage: 5,
    },
    {
      enabled: counts.created > 0,
      placeholderData: initialWorldsCuratedIn,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const worldsData = worldsByCreatorQuery.data;

  const worlds = worldsData ? worldsData.items : null;
  const worldsCount = worldsData ? worldsData.totalItems : 0;

  const hasHeading = user.name || user.username;

  const collectorsUiMobileUp = userCollectors ? (
    <Box
      css={{
        marginLeft: 'auto',
        '@bp2-max': {
          display: 'none',
        },
      }}
    >
      <Box
        css={{
          display: 'none',
          marginBottom: '$2',
          '@bp2': {
            alignSelf: 'flex-start',
            display: 'block',
          },
        }}
      >
        <ProfileCounts
          followers={followers}
          following={following}
          onFollowersClick={() => showModalTab('followers')}
          onFollowingClick={() => showModalTab('following')}
          auth={auth}
          publicKey={publicKey}
        />
      </Box>
    </Box>
  ) : null;

  const showDivider = worldsCount > 0 && userCollectors.length > 0;
  const showContainer = worldsCount > 0 || userCollectors.length > 0;

  if (showFullScreenModerationBlock) {
    return <ProfileWarningBlock moderationStatus={moderationStatus} />;
  }

  return (
    <>
      {showModerationBanner && (
        <ModerationBanner
          status={moderationStatus}
          messages={{
            UNDER_REVIEW: isMyProfile
              ? 'Your profile is under review.'
              : 'This profile is under review.',
            SUSPENDED: isMyProfile
              ? 'Your profile has been removed.'
              : 'This profile has been removed.',
            TAKEDOWN_REQUESTED:
              'Your profile has received a DMCA takedown notice.',
          }}
        />
      )}
      {isCurrentUserAdmin && (
        <AdminToolsModal
          tools={[
            {
              id: 'change-status',
              name: 'Change Moderation Status',
              ui: (
                <ChangeStatusPane
                  currentUserPublicKey={auth.publicKey}
                  entityId={publicKey}
                  moderationFrom=""
                  moderationStatus={moderationStatus}
                  mutation={setProfileModerationProxy}
                />
              ),
            },
            {
              id: 'remove-web2-data',
              name: 'Remove Web2 data',
              ui: <RemoveWeb2DataPane userPublicKey={publicKey} />,
            },
            {
              id: 'block-follows',
              name: 'Block Follows',
              ui: <BlockFollowsPane publicKey={publicKey} />,
            },
            {
              id: 'set-feature-flags',
              name: 'Set Feature Flags',
              ui: <SetFeatureFlagsPane publicKey={publicKey} />,
            },
          ]}
        />
      )}
      {hasPublicKey(auth) && (
        <ReportModal
          publicKey={auth.publicKey}
          reportedPublicKey={user.publicKey}
          pageType="Profile"
        />
      )}
      <ProfileRelatedWalletsModal
        collectors={props.counts.collectors}
        followers={followers}
        following={following}
        onChangeTab={(tab) => showModalTab(tab)}
        onClose={() => dispatchRelatedWallets({ type: 'close' })}
        open={relatedWalletsState.open}
        publicKey={publicKey}
        tab={relatedWalletsState.tab}
      />
      <Page
        absolute
        title={getProfilePageTitle(user)}
        description={truncateMetaDescription(user.bio ?? '')}
        image={openGraphImageUrl}
        headerMode="DARK"
      >
        <ProfileCoverImage
          avatar={avatarUrl}
          coverImage={
            coverImageUrl
              ? optimizeAsset(coverImageUrl, {
                  'max-h': 340,
                  'max-w': 2000,
                  fit: 'crop',
                })
              : null
          }
          publicKey={publicKey}
          meta={
            <Box
              css={{
                // offset by height of ProfileMenu trigger + 16px white-space
                transform: 'translateY(-52px)',
              }}
            >
              <ProfileMenu publicKey={publicKey} />
            </Box>
          }
        />
        <Box>
          <Body
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '$5',
              marginBottom: '$7',
              '@bp0': { marginBottom: '$8' },
            }}
          >
            {hasHeading && (
              <Flex
                css={{
                  // Offset the line height of the `h1`
                  marginBottom: -8,
                }}
              >
                <H1Heading
                  size={{ '@initial': 4, '@bp0': 6 }}
                  color={user.name ? 'strong' : 'dim'}
                  ellipsis
                  css={{ minWidth: 0 }}
                >
                  {user.name || formatUsername(user.username as string)}
                </H1Heading>
                {collectorsUiMobileUp}
              </Flex>
            )}
            <Flex
              css={{
                gap: '$3',
                alignItems: 'center',
                marginBottom: !user.bio ? '$1' : 0,
              }}
            >
              <IdentityMenu ens={ens} user={user} publicKey={publicKey} />
              <ProfileFollowButton
                publicKey={publicKey}
                size={0}
                onSuccess={(result) => {
                  setUserProfile((user) => {
                    if (!user) return user;
                    return {
                      ...user,
                      followerCount: {
                        aggregate: {
                          count:
                            result === 'FOLLOWED'
                              ? followers + 1
                              : followers - 1,
                        },
                      },
                    };
                  });
                }}
              />
              {!hasHeading && collectorsUiMobileUp}
            </Flex>
            {isMyProfile && <EmailCaptureBlock publicKey={publicKey} />}
            {user.bio && (
              <Box css={{ maxWidth: 500, color: '$black70' }}>
                <MarkdownText>{user.bio}</MarkdownText>
              </Box>
            )}
            <Box
              css={{
                display: 'block',
                '@bp2': { display: 'none' },
              }}
            >
              <ProfileCounts
                followers={followers}
                following={following}
                onFollowersClick={() => showModalTab('followers')}
                onFollowingClick={() => showModalTab('following')}
                auth={auth}
                publicKey={publicKey}
              />
            </Box>
            {showContainer && (
              <Flex
                css={{
                  gap: '$5',
                  flexWrap: 'wrap',
                  '@bp0': {
                    gap: '$3',
                  },
                }}
              >
                {userCollectors && userCollectors.length > 0 && (
                  <Box
                    css={{
                      alignSelf: 'flex-start',
                    }}
                  >
                    <ProfileCollectors
                      collectors={userCollectors}
                      collectorCount={props.counts.collectors}
                      onClick={() => showModalTab('collectors')}
                    />
                  </Box>
                )}
                {showDivider && (
                  <Divider
                    css={{
                      width: 1,
                      height: 'auto',
                      '@bp0-max': {
                        display: 'none',
                      },
                    }}
                  />
                )}
                {worlds && worldsCount > 0 && (
                  <ProfileWorlds
                    worlds={worlds}
                    worldsCount={worldsCount}
                    publicKey={publicKey}
                  />
                )}
              </Flex>
            )}
          </Body>
        </Box>
        {tabs.value ? (
          <Box css={{ flexGrow: 1 }}>
            <Tabs.Root
              value={tabs.value}
              onValueChange={(value: TabValue) => {
                tabs.setValue(value);
              }}
            >
              <StickyTabsContainer>
                <Tabs.Container
                  css={{
                    background: '$white90',
                    backdropFilter: 'saturate(150%) blur(20px) brightness(1.5)',
                  }}
                >
                  <Body>
                    <Tabs.List>
                      {hasHomeTab && (
                        <Tabs.Trigger value="home">
                          <Home />
                        </Tabs.Trigger>
                      )}
                      {counts.created > 0 && (
                        <Tabs.Trigger value="created">
                          Created{' '}
                          <Tabs.Count>
                            {abbreviateValue(counts.created)}
                          </Tabs.Count>
                        </Tabs.Trigger>
                      )}
                      {counts.collections > 0 && (
                        <Tabs.Trigger value="collections">
                          Collections{' '}
                          <Tabs.Count>
                            {abbreviateValue(counts.collections)}
                          </Tabs.Count>
                        </Tabs.Trigger>
                      )}
                      {counts.splits > 0 && (
                        <Tabs.Trigger value="splits">
                          Splits{' '}
                          <Tabs.Count>
                            {abbreviateValue(counts.splits)}
                          </Tabs.Count>
                        </Tabs.Trigger>
                      )}
                      {counts.owned > 0 && (
                        <Tabs.Trigger value="owned">
                          Owned{' '}
                          <Tabs.Count>
                            {abbreviateValue(counts.owned)}
                          </Tabs.Count>
                        </Tabs.Trigger>
                      )}

                      {counts.worlds > 0 && (
                        <Tabs.Trigger value="worlds">
                          {PRODUCT_BRANDING.world.plural}{' '}
                          <Tabs.Count>
                            {abbreviateValue(counts.worlds)}
                          </Tabs.Count>
                        </Tabs.Trigger>
                      )}
                      {counts.hidden > 0 && isMyProfile && (
                        <Tabs.Trigger value="hidden">
                          Hidden{' '}
                          <Tabs.Count>
                            {abbreviateValue(counts.hidden)}
                          </Tabs.Count>
                        </Tabs.Trigger>
                      )}
                    </Tabs.List>
                  </Body>
                </Tabs.Container>
              </StickyTabsContainer>
              <Box>
                <Body>
                  <Tabs.Content css={{ flexGrow: 1 }} value="home">
                    <ProfileHomeTab
                      canCurateHomeTab={isMyProfile}
                      homeTabCuration={homeTabCuration}
                      publicKey={publicKey}
                      skeletonCardCount={homeTabItems.length}
                      setProfileTab={tabs.setValue}
                    />
                  </Tabs.Content>
                  <Tabs.Content value="worlds">
                    <WorldsTab
                      publicKey={publicKey}
                      skeletonCardCount={counts.worlds}
                      indexedStates={indexedStates}
                    />
                  </Tabs.Content>
                  <Tabs.Content value="created">
                    <CreatedTab
                      canCurateHomeTab={isMyProfile}
                      homeTabCuration={homeTabCuration}
                      publicKey={publicKey}
                      skeletonCardCount={counts.created}
                      isOwnerOrAdmin={isOwnerOrAdmin}
                      indexedStates={indexedStates}
                    />
                  </Tabs.Content>
                  <Tabs.Content value="owned">
                    <OwnedTab
                      canCurateHomeTab={isMyProfile}
                      homeTabCuration={homeTabCuration}
                      publicKey={publicKey}
                      skeletonCardCount={counts.owned}
                      indexedStates={indexedStates}
                    />
                  </Tabs.Content>
                  <Tabs.Content value="collections">
                    <CollectionsTab
                      publicKey={publicKey}
                      skeletonCardCount={counts.collections}
                      indexedStates={indexedStates}
                    />
                  </Tabs.Content>
                  <Tabs.Content value="splits">
                    <SplitsTab
                      canCurateHomeTab={isMyProfile}
                      homeTabCuration={homeTabCuration}
                      publicKey={publicKey}
                      skeletonCardCount={counts.splits}
                      indexedStates={indexedStates}
                    />
                  </Tabs.Content>
                  <Tabs.Content value="hidden">
                    <HiddenTab
                      publicKey={publicKey}
                      skeletonCardCount={counts.hidden}
                      indexedStates={indexedStates}
                    />
                  </Tabs.Content>
                </Body>
              </Box>
            </Tabs.Root>
          </Box>
        ) : (
          <EmptyStateContainer>
            <Body>
              {isMyProfile && (
                <Empty
                  heading="You don’t own any NFTs yet"
                  subheading="Get started by creating your first NFT or browse to find something to add your collection."
                  actions={
                    <>
                      <NextLink href="/create" passHref>
                        <Button as="a" size={0}>
                          Create
                        </Button>
                      </NextLink>
                      <NextLink href="/nfts" passHref>
                        <Button as="a" size={0}>
                          Browse
                        </Button>
                      </NextLink>
                    </>
                  }
                />
              )}
            </Body>
          </EmptyStateContainer>
        )}
      </Page>
    </>
  );
}

const StickyTabsContainer = styled('div', {
  position: 'sticky',
  top: 0,
  zIndex: 1,
});

const EmptyStateContainer = styled('div', {
  borderTop: '1px solid $black5',
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
});

const COUNT_ITEM_TAB_ORDER: Array<keyof ProfileTabCounts> = [
  'worlds',
  'created',
  'owned',
  'collections',
  'splits',
  'hidden',
];

type UseProfileTabsOptions = {
  counts: ProfileTabCounts;
  homeTabItems: HomeTabItem[];
};

const findFirstAvailableStaticTab = (
  options: UseProfileTabsOptions
): TabValue | undefined => {
  if (options.homeTabItems.length > 0) {
    return 'home';
  }

  const firstTab = COUNT_ITEM_TAB_ORDER.find((key) => options.counts[key] > 0);

  return firstTab;
};

const useProfileTabs = (options: UseProfileTabsOptions) => {
  const router = useRouter();
  const isHydrated = useIsHydrated();
  const [activeTabValue, setActiveTabValue] = useState<TabValue | undefined>(
    findFirstAvailableStaticTab(options)
  );

  /**
   * Read the deep link tab param and use it as the active tab if it is a supported string
   */
  useEffect(() => {
    const urlParams = profileUrlParams.get(router.query);

    // If `tabKey` is present in URL params, set the active tab to Home if Home items exist
    if (urlParams === null || !urlParams.tabValue) {
      if (options.homeTabItems.length > 0) {
        return setActiveTabValue('home');
      } else {
        return;
      }
    }

    setActiveTabValue(urlParams.tabValue);
  }, [router.isReady, options.homeTabItems, isHydrated]);

  const setValue = (value: TabValue) => {
    setActiveTabValue(value);
    profileUrlParams.set({ tabValue: value });
  };

  return {
    value: activeTabValue,
    setValue,
  };
};

type PageParams = {
  username: string;
};

type MapToProfilePropsOptions = BaseProfileProps & {
  tabCounts: ProfileTabCounts;
  homeTabItems: HomeTabItem[];
  user: ProfileUser;
  worldsCuratedIn: UnsafeAny;
};

function mapToProfileProps(
  options: MapToProfilePropsOptions
): ProfilePageProps {
  const { ens, homeTabItems, publicKey, tabCounts, user, worldsCuratedIn } =
    options;

  return {
    user,
    counts: {
      ...tabCounts,
      collectors: getAggregateCount(user.collectorsCount),
      followers: getAggregateCount(user.followerCount),
      following: getAggregateCount(user.followingCount),
    },
    homeTabItems,
    ens,
    publicKey,
    worldsCuratedIn,
    type: 'PROFILE',
  };
}

export async function getStaticPaths(): Promise<GetStaticPathsResult> {
  return {
    paths: [],
    fallback: 'blocking',
  };
}

export async function getStaticProps(
  context: GetStaticPropsContext<PageParams>
): Promise<GetStaticPropsResult<ProfilePageProps>> {
  if (!context.params?.username) {
    throw new Error('username param must be set');
  }

  /**
   * the username param can be a username OR an address
   * if it is a username, we strip the leading @ symbol here
   */
  const usernameOrPublicKey = context.params.username.replace(/^@/, '');
  const parsedAddress = addressValueSchema.safeParse(usernameOrPublicKey);

  /**
   * if the username is an address/publicKey, check if we have a user for that publicKey
   */
  if (parsedAddress.success) {
    const publicKey = parsedAddress.data;

    const { ens, homeTabItems, tabCounts, user, worldsCuratedIn } =
      await getProfileData(publicKey);

    if (user && tabCounts && homeTabItems) {
      return {
        props: {
          ...mapToProfileProps({
            tabCounts,
            ens,
            homeTabItems,
            publicKey,
            user,
            worldsCuratedIn,
          }),
        },
        revalidate: REVALIDATE_TIME,
      };
    } else {
      return {
        props: {
          ens,
          publicKey,
          type: 'ANON',
        },
        revalidate: REVALIDATE_TIME,
      };
    }
  }

  // Since we already checked if it's an address, we can assume it's a username now
  const username = usernameOrPublicKey;

  const publicKey = await getUserPublicKeyByUsername(username);

  if (!publicKey) {
    return NOT_FOUND;
  }

  const { ens, tabCounts, user, homeTabItems, worldsCuratedIn } =
    await getProfileData(publicKey);

  if (!user || !tabCounts || !homeTabItems) {
    return NOT_FOUND;
  }

  return {
    props: {
      ...mapToProfileProps({
        tabCounts,
        ens,
        homeTabItems,
        publicKey,
        user,
        worldsCuratedIn,
      }),
    },
    revalidate: REVALIDATE_TIME,
  };
}

async function getProfileData(publicKey: string) {
  const [
    profileResult,
    ensResult,
    tabCountsResult,
    homeTabItemsResult,
    worldResult,
  ] = await Promise.allSettled([
    getUserProfile({ publicKey }),
    getENSNameByPublicKey({ publicKey }),
    getProfileTabCounts({ publicKey }),
    getHomeTabItems({
      accountAddress: publicKey,
      type: 'PROFILE',
    }),
    getWorldsByCreator({ publicKey, perPage: 5 }),
  ]);

  return {
    ens: getPromiseResult(ensResult),
    homeTabItems: getPromiseResult(homeTabItemsResult),
    tabCounts: getPromiseResult(tabCountsResult),
    user: getPromiseResult(profileResult),
    worldsCuratedIn: getPromiseResult(worldResult),
  };
}
