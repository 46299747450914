import { keepPreviousData } from '@tanstack/react-query';

import Modal from 'components/base/Modal';
import Tabs from 'components/base/Tabs';
import VirtualisedUserListEmptyState from 'components/modals/virtualized/VirtualisedUserListEmptyState';
import VirtualisedUserListLoading from 'components/modals/virtualized/VirtualisedUserListLoading';
import VirtualizedUserList from 'components/modals/virtualized/VirtualizedUserList';
import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { useInfiniteUserCollectors } from 'gql/hasura/queries/user-collectors.generated';
import {
  UserFollowers,
  useInfiniteUserFollowers,
} from 'gql/hasura/queries/user-followers.generated';
import { useInfiniteUserFollowing } from 'gql/hasura/queries/user-following.generated';
import { useInfiniteQueryItems } from 'hooks/use-infinite-query-items';
import { useTabbedModal } from 'hooks/use-tabbed-modal';
import { formatNumber } from 'utils/formatters';
import { hasuraPaginator } from 'utils/react-query';

const mapUserFollowers = (data: UserFollowers) => {
  if (data.user) {
    return { items: data.user.items.map((item) => item.user) };
  } else {
    return { items: [] };
  }
};

export type ProfileRelatedWalletsModalTab =
  | 'collectors'
  | 'following'
  | 'followers';

export function useProfileRelatedWalletsModal(
  initialTab: ProfileRelatedWalletsModalTab
) {
  return useTabbedModal<ProfileRelatedWalletsModalTab>(initialTab);
}

type ProfileRelatedWalletsModalProps = {
  collectors: number;
  followers: number;
  following: number;
  open: boolean;
  onChangeTab: (tab: ProfileRelatedWalletsModalTab) => void;
  onClose: () => void;
  publicKey: string;
  tab: ProfileRelatedWalletsModalTab;
};

export default function ProfileRelatedWalletsModal(
  props: ProfileRelatedWalletsModalProps
) {
  const { tab, open, onChangeTab, onClose, publicKey } = props;

  const auth = useAuth();
  const currentUserPublicKey = hasPublicKey(auth) ? auth.publicKey : undefined;

  const hasCollectors = props.collectors > 0;

  return (
    <Modal.Root
      open={open}
      onOpenChange={(open) => {
        if (open === false) {
          onClose();
        }
      }}
    >
      <Tabs.Root
        value={tab || undefined}
        onValueChange={(value) => {
          onChangeTab(value as ProfileRelatedWalletsModalTab);
        }}
      >
        <Modal.Content
          height="normal"
          header={
            <Modal.Header>
              <Tabs.List>
                {hasCollectors && (
                  <Tabs.Trigger value="collectors">
                    Collectors{' '}
                    <Tabs.Count>{formatNumber(props.collectors)}</Tabs.Count>
                  </Tabs.Trigger>
                )}
                <Tabs.Trigger value="following">
                  Following{' '}
                  <Tabs.Count>{formatNumber(props.following)}</Tabs.Count>
                </Tabs.Trigger>
                <Tabs.Trigger value="followers">
                  Followers{' '}
                  <Tabs.Count>{formatNumber(props.followers)}</Tabs.Count>
                </Tabs.Trigger>
              </Tabs.List>
            </Modal.Header>
          }
          size={0}
        >
          <Tabs.Content value="collectors">
            <CollectorsTab
              currentUserPublicKey={currentUserPublicKey}
              publicKey={publicKey}
            />
          </Tabs.Content>
          <Tabs.Content value="following">
            <FollowingTab
              currentUserPublicKey={currentUserPublicKey}
              publicKey={publicKey}
            />
          </Tabs.Content>
          <Tabs.Content value="followers">
            <FollowersTab
              currentUserPublicKey={currentUserPublicKey}
              publicKey={publicKey}
            />
          </Tabs.Content>
        </Modal.Content>
      </Tabs.Root>
    </Modal.Root>
  );
}

interface RelatedWalletTabProps {
  currentUserPublicKey?: string;
  publicKey: string;
}

/**
 * Note: The CollectorsTab, FollowingTab, and FollowersTab components are screaming out to be refactored into a single component.
 * I kept all three as seperate components for now, because there are extemely subtle differences in the query across all three.
 * To standardize, we should look into:
 *
 * 1. Standardizing the query to fetch the data via the API.
 * 2. Updating the VirtualizedUserList component to accept a simplified type.
 */
function CollectorsTab(props: RelatedWalletTabProps) {
  const { currentUserPublicKey, publicKey } = props;

  const userCollectorsQuery = useInfiniteUserCollectors(
    {
      publicKey,
      currentUserPublicKey: currentUserPublicKey || '',
      ...hasuraPaginator.initialPageParam,
    },
    {
      initialPageParam: hasuraPaginator.initialPageParam,
      getNextPageParam: hasuraPaginator.getNextPageParam,
      placeholderData: keepPreviousData,
    }
  );

  const collectors = useInfiniteQueryItems(userCollectorsQuery, 'publicKey');

  if (userCollectorsQuery.isLoading) {
    return <VirtualisedUserListLoading />;
  }

  return (
    <VirtualizedUserList
      users={collectors}
      handleNextPage={() => userCollectorsQuery.fetchNextPage()}
      isFetchingNextPage={userCollectorsQuery.isFetchingNextPage}
      hasNextPage={Boolean(userCollectorsQuery.hasNextPage)}
      onFollowUpdate={() => {
        userCollectorsQuery.refetch();
      }}
    />
  );
}

/**
 * Note: The CollectorsTab, FollowingTab, and FollowersTab components are screaming out to be refactored into a single component.
 * I kept all three as seperate components for now, because there are extemely subtle differences in the query across all three.
 * To standardize, we should look into:
 *
 * 1. Standardizing the query to fetch the data via the API.
 * 2. Updating the VirtualizedUserList component to accept a simplified type.
 */
function FollowingTab(props: RelatedWalletTabProps) {
  const { currentUserPublicKey, publicKey } = props;

  const userFollowingQuery = useInfiniteUserFollowing(
    {
      publicKey,
      currentUserPublicKey: currentUserPublicKey || '',
      ...hasuraPaginator.initialPageParam,
    },
    {
      initialPageParam: hasuraPaginator.initialPageParam,
      getNextPageParam: (lastPage, allPages) => {
        return hasuraPaginator.getNextPageParam(
          mapUserFollowers(lastPage),
          allPages.map(mapUserFollowers)
        );
      },
      placeholderData: keepPreviousData,
      select: (data) => ({
        pages: data.pages.map(mapUserFollowers),
        pageParams: data.pageParams,
      }),
    }
  );

  const users = useInfiniteQueryItems(userFollowingQuery, 'publicKey');

  if (userFollowingQuery.isLoading) {
    return <VirtualisedUserListLoading />;
  }

  if (!userFollowingQuery.data) {
    return <NoFollowing />;
  }

  if (users.length === 0) {
    return <NoFollowing />;
  }

  return (
    <VirtualizedUserList
      users={users}
      handleNextPage={() => userFollowingQuery.fetchNextPage()}
      isFetchingNextPage={userFollowingQuery.isFetchingNextPage}
      hasNextPage={Boolean(userFollowingQuery.hasNextPage)}
      onFollowUpdate={() => {
        userFollowingQuery.refetch();
      }}
    />
  );
}

/**
 * Note: The CollectorsTab, FollowingTab, and FollowersTab components are screaming out to be refactored into a single component.
 * I kept all three as seperate components for now, because there are extemely subtle differences in the query across all three.
 * To standardize, we should look into:
 *
 * 1. Standardizing the query to fetch the data via the API.
 * 2. Updating the VirtualizedUserList component to accept a simplified type.
 */

function FollowersTab(props: RelatedWalletTabProps) {
  const { currentUserPublicKey, publicKey } = props;

  const userFollowersQuery = useInfiniteUserFollowers(
    {
      publicKey,
      currentUserPublicKey: currentUserPublicKey || '',
      ...hasuraPaginator.initialPageParam,
    },
    {
      initialPageParam: hasuraPaginator.initialPageParam,
      getNextPageParam: (lastPage, allPages) => {
        return hasuraPaginator.getNextPageParam(
          mapUserFollowers(lastPage),
          allPages.map(mapUserFollowers)
        );
      },
      placeholderData: keepPreviousData,
      select: (data) => ({
        pages: data.pages.map(mapUserFollowers),
        pageParams: data.pageParams,
      }),
    }
  );

  const users = useInfiniteQueryItems(userFollowersQuery, 'publicKey');

  if (userFollowersQuery.isLoading) {
    return <VirtualisedUserListLoading />;
  }

  if (!userFollowersQuery.data) {
    return <NoFollowers />;
  }

  if (users.length === 0) {
    return <NoFollowers />;
  }

  return (
    <VirtualizedUserList
      users={users}
      handleNextPage={() => userFollowersQuery.fetchNextPage()}
      isFetchingNextPage={userFollowersQuery.isFetchingNextPage}
      hasNextPage={Boolean(userFollowersQuery.hasNextPage)}
      onFollowUpdate={() => {
        userFollowersQuery.refetch();
      }}
    />
  );
}

function NoFollowers() {
  return (
    <VirtualisedUserListEmptyState
      heading="No followers"
      subheading="This user has not been followed by anyone"
    />
  );
}

function NoFollowing() {
  return (
    <VirtualisedUserListEmptyState
      heading="No following"
      subheading="This user is not following anyone"
    />
  );
}
