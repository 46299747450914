import { keepPreviousData, useQueryClient } from '@tanstack/react-query';

import CardGrid from 'components/CardGrid';
import ArtworkCard, {
  ArtworkCardProps,
} from 'components/cards/artwork/ArtworkCard';
import ArtworkCardSkeletonGrid from 'components/cards/artwork/ArtworkCardSkeletonGrid';
import { useToggleArtworkHiddenMenuOption } from 'components/dropdown/artwork';
import InfiniteScrollButton from 'components/feed/InfiniteScrollButton';
import { hasToken } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useNotifications from 'state/stores/notifications';

import { useInfiniteUserArtworksHidden } from 'gql/hasura/queries/user-artworks-hidden.generated';
import { useSetArtworkUserVisibility } from 'gql/server/mutations/set-artwork-user-visibility.generated';
import useAssetFallbackByArtworkIds from 'hooks/queries/hasura/artworks/use-asset-fallback-by-artwork-ids';
import useProfileTabCounts from 'hooks/queries/hasura/profile/use-profile-tab-counts';
import { getBaseKey } from 'hooks/queries/shared';
import { useInfiniteQueryItems } from 'hooks/use-infinite-query-items';
import useModal from 'hooks/use-modal';
import { TEMPORARILY_HIDDEN_CONTRACT_ADDRESSES } from 'lib/addresses';
import { findFallbackAsset, mapFailedArtworksToIds } from 'utils/assets';
import { hasuraPaginator } from 'utils/react-query';

import { UserLight } from 'types/Account';

import FilterLayout from './FilterLayout';
import { ProfileTabsProps } from './types';

type HiddenTab = ProfileTabsProps;

export default function HiddenTab(props: HiddenTab) {
  const userArtworksHiddenQuery = useInfiniteUserArtworksHidden(
    {
      publicKey: props.publicKey,
      indexedStates: props.indexedStates,
      hiddenCollections: TEMPORARILY_HIDDEN_CONTRACT_ADDRESSES,
      ...hasuraPaginator.initialPageParam,
    },
    {
      initialPageParam: hasuraPaginator.initialPageParam,
      getNextPageParam: hasuraPaginator.getNextPageParam,
      placeholderData: keepPreviousData,
    }
  );

  const artworks = useInfiniteQueryItems(userArtworksHiddenQuery, 'id');

  const fallbackArtworkIds = mapFailedArtworksToIds(artworks);
  const fallbackAssetsQuery = useAssetFallbackByArtworkIds({
    artworkIds: fallbackArtworkIds,
  });
  const auth = useAuth();
  const modal = useModal();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const setArtworkUserVisibility = useSetArtworkUserVisibility({
    onSuccess: () => {
      userArtworksHiddenQuery.refetch();
      queryClient.invalidateQueries({
        queryKey: [getBaseKey(useProfileTabCounts.getKey)],
      });
      notifications.show.success({
        message: 'NFT added to profile',
      });
    },
  });

  if (userArtworksHiddenQuery.isLoading) {
    return (
      <FilterLayout filters={null}>
        <ArtworkCardSkeletonGrid items={props.skeletonCardCount} />
      </FilterLayout>
    );
  }

  return (
    <FilterLayout filters={null}>
      <CardGrid.Root>
        {artworks.map((artwork) => {
          const fallbackAsset = findFallbackAsset(
            fallbackAssetsQuery.data?.asset,
            artwork?.id
          );
          return (
            <HiddenTabArtworkCard
              key={`${artwork.tokenId}-${artwork.contractAddress}`}
              artwork={artwork}
              creator={artwork.creator as UserLight}
              fallbackAsset={fallbackAsset}
              onToggleHidden={() => {
                if (!hasToken(auth)) {
                  modal.setModal({ type: 'AUTH_PRE_SIGN' });
                  return;
                }

                if (!artwork.contractAddress || !artwork.tokenId) {
                  return;
                }

                setArtworkUserVisibility.mutate({
                  contractAddress: artwork.contractAddress,
                  shouldHide: false,
                  tokenId: artwork.tokenId,
                });
              }}
              publicKey={props.publicKey}
            />
          );
        })}
      </CardGrid.Root>
      <InfiniteScrollButton
        handleNextPage={userArtworksHiddenQuery.fetchNextPage}
        isFetching={userArtworksHiddenQuery.isFetching}
        hasNextPage={userArtworksHiddenQuery.hasNextPage}
      />
    </FilterLayout>
  );
}

function HiddenTabArtworkCard(
  props: Omit<ArtworkCardProps, 'menuOptions'> & {
    onToggleHidden(): void;
    publicKey: string;
  }
) {
  const { onToggleHidden, ...artworkProps } = props;
  const toggleHiddenMenuOption = useToggleArtworkHiddenMenuOption(
    {
      contractAddress: props.artwork.contractAddress,
      publicKey: props.publicKey,
      shouldHide: false,
      tokenId: props.artwork.tokenId,
    },
    {
      onSuccess: () => onToggleHidden(),
    }
  );

  return (
    <ArtworkCard {...artworkProps} menuOptions={[toggleHiddenMenuOption]} />
  );
}
