import { ChevronRightIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { useSingleton } from '@tippyjs/react/headless';
import NextLink from 'next/link';
import { Dispatch, memo, SetStateAction, useState } from 'react';

import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Heading from 'components/base/Heading';
import Link from 'components/base/Link';
import Modal from 'components/base/Modal';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import InfiniteScrollButton from 'components/feed/InfiniteScrollButton';
import { WorldHoverCardSingleton } from 'components/worlds/WorldHoverCardSingleton';
import WorldStackInteractive from 'components/worlds/WorldStackInteractive';
import WorldTag from 'components/worlds/WorldTag';

import { useInfiniteExhibitionsByCreator } from 'gql/api/queries/exhibitions-by-creator.generated';
import { abbreviateValue } from 'utils/formatters';
import { apiPaginator, extractNestedPaginatedData } from 'utils/react-query';
import { getPath } from 'utils/router';
import { pluralize } from 'utils/strings';

import { WorldOverview } from 'types/World';

interface ProfileWorldsProps {
  worlds: WorldOverview[];
  worldsCount: number;
  publicKey: string;
}

export default function ProfileWorlds(props: ProfileWorldsProps) {
  const { worlds, worldsCount, publicKey } = props;
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <Flex css={{ alignItems: 'center' }}>
      <ProfileWorldsModal
        publicKey={publicKey}
        setModalOpen={setModalOpen}
        isModalOpen={isModalOpen}
        worldsCount={worldsCount}
      />
      <Box css={{ position: 'relative', zIndex: 2 }}>
        <MemoizedWorldStack worlds={worlds} />
      </Box>
      <Heading
        onClick={() => setModalOpen(true)}
        color="strong"
        css={{
          cursor: 'pointer',
          marginLeft: '$2',
          whiteSpace: 'nowrap',
          '@hover': {
            '&:hover': {
              color: '$black70',
            },
          },
        }}
      >
        Curated in <Text as="span">{abbreviateValue(worldsCount)}</Text>
        {` `}
        <Text as="span">
          {pluralize({
            singular: 'gallery',
            plural: 'galleries',
            count: worldsCount,
          })}
        </Text>
      </Heading>
    </Flex>
  );
}

interface MemoizedWorldStackProps {
  worlds: WorldOverview[];
}
const MemoizedWorldStack = memo(function WorldPopovers(
  props: MemoizedWorldStackProps
) {
  const [source, target] = useSingleton();
  const { worlds } = props;

  return (
    <>
      <WorldHoverCardSingleton source={source} placement="bottom" />
      <WorldStackInteractive worlds={worlds} target={target} />
    </>
  );
});

interface ProfileWorldsModalProps {
  isModalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  publicKey: string;
  worldsCount: number;
}

function ProfileWorldsModal(props: ProfileWorldsModalProps) {
  const { isModalOpen, setModalOpen, publicKey, worldsCount } = props;
  const worldsByCreatorQuery = useInfiniteExhibitionsByCreator(
    { publicKey, perPage: 50 },
    {
      enabled: worldsCount > 0,
      getNextPageParam: (lastPage) => {
        return apiPaginator.getNextPageParam(lastPage.exhibitionsByCreator);
      },
      initialPageParam: apiPaginator.initialPageParam,
    }
  );

  const worlds = extractNestedPaginatedData(
    worldsByCreatorQuery.data,
    'exhibitionsByCreator'
  );
  return (
    <Modal.Root open={isModalOpen} onOpenChange={setModalOpen}>
      <Modal.Content size={1}>
        <Box css={{ paddingX: '$2', marginBottom: '$4' }}>
          <Modal.BodyTitle title="Galleries" />
        </Box>
        <Box css={{ paddingBottom: '$2' }}>
          <WorldListHeading>Curated in</WorldListHeading>
          {worldsByCreatorQuery.isLoading ? (
            <ProfileWorldsSkeleton worldsCount={worldsCount} />
          ) : (
            <WorldsList
              worlds={worlds.items}
              handleNextPage={worldsByCreatorQuery.fetchNextPage}
              hasNextPage={worldsByCreatorQuery.hasNextPage}
              isFetching={worldsByCreatorQuery.isFetchingNextPage}
            />
          )}
        </Box>
      </Modal.Content>
    </Modal.Root>
  );
}

interface WorldsListProps {
  worlds: WorldOverview[];
  handleNextPage: () => void;
  hasNextPage?: boolean;
  isFetching: boolean;
}

function WorldsList(props: WorldsListProps) {
  const { worlds, handleNextPage, hasNextPage, isFetching } = props;
  return (
    <Flex css={{ flexDirection: 'column' }}>
      {worlds.map((world) => (
        <NextLink
          passHref
          prefetch={false}
          key={world.slug}
          href={getPath.world.page(world.slug)}
        >
          <ListItemWrapper as={Link}>
            <WorldTag type="list-item" world={world} />
            <ChevronRightIcon />
          </ListItemWrapper>
        </NextLink>
      ))}
      <InfiniteScrollButton
        handleNextPage={handleNextPage}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
      />
    </Flex>
  );
}

const ListItemWrapper = styled('div', {
  display: 'flex',
  gap: '$3',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '$3',
  borderRadius: '$2',
  svg: {
    opacity: 0,
    color: '$black70',
  },
  '@hover': {
    '&:hover': {
      backgroundColor: '$black5',
      svg: {
        opacity: 1,
      },
    },
  },
});

const WorldListHeading = styled(Text, {
  marginBottom: '$2',
  paddingX: '$3',
});
WorldListHeading.defaultProps = {
  color: 'dim',
  weight: 'semibold',
};

function ProfileWorldsSkeleton(props: { worldsCount: number }) {
  return (
    <Flex
      css={{
        flexDirection: 'column',
        gap: '2px',
        paddingX: '$3',
      }}
    >
      <Skeleton.Text css={{ marginBottom: '$3' }}>
        Currently listed in
      </Skeleton.Text>
      {Array.from({ length: Math.min(4, props.worldsCount) }).map(
        (_, index) => {
          return (
            <Skeleton.Block
              css={{
                height: '72px',
                width: '100%',
              }}
              key={index}
            />
          );
        }
      )}
    </Flex>
  );
}
