import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import {
  UserProfileByPublicKey,
  UserProfileByPublicKeyVariables,
  useUserProfileByPublicKey,
} from 'gql/hasura/queries/user-profile-by-public-key.generated';
import { getQueryDataSetterForQueryKey } from 'hooks/queries/shared';

type ProfileUser = UserProfileByPublicKey['user'];

export async function getUserProfile(
  variables: UserProfileByPublicKeyVariables
): Promise<ProfileUser> {
  const fetchUserProfile = useUserProfileByPublicKey.fetcher(variables);
  const data = await fetchUserProfile();

  return data.user;
}

function useUserProfile(
  variables: UserProfileByPublicKeyVariables,
  options: Pick<
    UseQueryOptions<ProfileUser>,
    'refetchOnMount' | 'refetchOnWindowFocus' | 'placeholderData'
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: useUserProfile.getKey(variables),
    queryFn: () => getUserProfile(variables),
  });
}

useUserProfile.getKey = (variables: UserProfileByPublicKeyVariables) => {
  return ['UserProfile', variables.publicKey];
};

useUserProfile.getDataSetter = (variables: UserProfileByPublicKeyVariables) =>
  getQueryDataSetterForQueryKey<ProfileUser | undefined>(
    useUserProfile.getKey(variables)
  );

export default useUserProfile;
