import { HideIcon } from '@f8n/icons';
import { keepPreviousData } from '@tanstack/react-query';
import { useMemo } from 'react';

import CardGrid from 'components/CardGrid';
import ArtworkCard, {
  ArtworkCardProps,
} from 'components/cards/artwork/ArtworkCard';
import ArtworkCardSkeletonGrid from 'components/cards/artwork/ArtworkCardSkeletonGrid';
import { useToggleArtworkHiddenMenuOption } from 'components/dropdown/artwork';
import InfiniteScrollButton from 'components/feed/InfiniteScrollButton';
import { useHomeTabCurationOption } from 'components/home-tab';
import { PopoverMenuOption } from 'components/popover/types';
import { hasToken } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useNotifications from 'state/stores/notifications';

import { useInfiniteUserArtworksSplits } from 'gql/hasura/queries/user-artworks-splits.generated';
import { useSetArtworkUserVisibility } from 'gql/server/mutations/set-artwork-user-visibility.generated';
import useAssetFallbackByArtworkIds from 'hooks/queries/hasura/artworks/use-asset-fallback-by-artwork-ids';
import { useInfiniteQueryItems } from 'hooks/use-infinite-query-items';
import useModal from 'hooks/use-modal';
import { TEMPORARILY_HIDDEN_CONTRACT_ADDRESSES } from 'lib/addresses';
import { findFallbackAsset, mapFailedArtworksToIds } from 'utils/assets';
import { notEmptyOrNil } from 'utils/helpers';
import { hasuraPaginator } from 'utils/react-query';
import { areKeysEqual } from 'utils/users';

import { HomeTabCurationProps } from 'types/HomeTab';

import FilterLayout from './FilterLayout';
import { ProfileTabsProps } from './types';

type SplitsTab = ProfileTabsProps & HomeTabCurationProps;

export default function SplitsTab(props: SplitsTab) {
  const auth = useAuth();
  const notifications = useNotifications();

  const userArtworksSplitsQuery = useInfiniteUserArtworksSplits(
    {
      publicKey: props.publicKey,
      indexedStates: props.indexedStates,
      hiddenCollections: TEMPORARILY_HIDDEN_CONTRACT_ADDRESSES,
      ...hasuraPaginator.initialPageParam,
    },
    {
      initialPageParam: hasuraPaginator.initialPageParam,
      getNextPageParam: hasuraPaginator.getNextPageParam,
      placeholderData: keepPreviousData,
      select: (data) => ({
        pages: data.pages.map((page) => ({
          items: page.items.filter((artwork) => {
            return artwork.artworkUserVisibilities.length === 0;
          }),
        })),
        pageParams: data.pageParams,
      }),
    }
  );

  const query = userArtworksSplitsQuery;

  const modal = useModal();
  const artworks = useInfiniteQueryItems(query, 'id');
  const fallbackArtworkIds = mapFailedArtworksToIds(artworks);
  const fallbackAssetsQuery = useAssetFallbackByArtworkIds({
    artworkIds: fallbackArtworkIds,
  });

  const setArtworkUserVisibility = useSetArtworkUserVisibility({
    onSuccess: () => {
      notifications.show.info({
        message: 'NFT hidden from profile',
        icon: <HideIcon />,
      });
    },
  });

  if (query.isLoading) {
    return (
      <FilterLayout filters={null}>
        <ArtworkCardSkeletonGrid items={props.skeletonCardCount} />
      </FilterLayout>
    );
  }

  return (
    <FilterLayout filters={null}>
      <CardGrid.Root>
        {artworks.map((artwork) => {
          const fallbackAsset = findFallbackAsset(
            fallbackAssetsQuery.data?.asset,
            artwork?.id
          );

          const isCreator = areKeysEqual([artwork?.publicKey, props.publicKey]);
          const canHide = !isCreator;
          const isHidden = notEmptyOrNil(artwork?.artworkUserVisibilities);

          return (
            <SplitsTabArtworkCard
              key={`${artwork.tokenId}-${artwork.contractAddress}`}
              artwork={artwork}
              // @ts-expect-error null-checks
              creator={artwork.creator}
              fallbackAsset={fallbackAsset}
              homeTabCuration={props.homeTabCuration}
              canCurateHomeTab={props.canCurateHomeTab}
              canHide={canHide}
              isHidden={isHidden}
              onToggleHidden={() => {
                if (!hasToken(auth)) {
                  modal.setModal({ type: 'AUTH_PRE_SIGN' });
                  return;
                }

                if (!artwork.contractAddress || !artwork.tokenId) {
                  return;
                }

                setArtworkUserVisibility.mutate({
                  contractAddress: artwork.contractAddress,
                  shouldHide: !isHidden,
                  tokenId: artwork.tokenId,
                });
              }}
              publicKey={props.publicKey}
            />
          );
        })}
      </CardGrid.Root>
      <InfiniteScrollButton
        handleNextPage={query.fetchNextPage}
        isFetching={query.isFetching}
        hasNextPage={query.hasNextPage}
      />
    </FilterLayout>
  );
}

type SplitsTabArtworkCardProps = HomeTabCurationProps &
  Omit<ArtworkCardProps, 'menuOptions'> & {
    canHide: boolean;
    isHidden: boolean;
    onToggleHidden(): void;
    publicKey: string;
  };

function SplitsTabArtworkCard(props: SplitsTabArtworkCardProps) {
  const {
    homeTabCuration,
    canCurateHomeTab,
    canHide,
    isHidden,
    onToggleHidden,
    publicKey,
  } = props;

  const id = props.artwork.id;

  const homeTabOption = useHomeTabCurationOption({
    homeTabCuration,
    canCurateHomeTab,
    nftId: id,
    type: 'profile',
  });
  const toggleHiddenMenuOption = useToggleArtworkHiddenMenuOption(
    {
      contractAddress: props.artwork.contractAddress,
      tokenId: props.artwork.tokenId,
      shouldHide: !isHidden,
      publicKey,
    },
    {
      onMutate: () => {
        // TODO: optimistically remove the item from the list
      },
      onSuccess: () => onToggleHidden(),
    }
  );

  const menuOptions = useMemo(() => {
    const options: PopoverMenuOption[] = [];

    if (canHide) {
      options.push(toggleHiddenMenuOption);
    }

    if (homeTabOption) {
      options.push(homeTabOption);
    }

    return options;
  }, [homeTabOption]);

  return <ArtworkCard {...props} menuOptions={menuOptions} />;
}
