import { styled } from '@f8n-frontend/stitches';

import Empty from 'components/Empty';
import Button from 'components/base/Button';

import EllipsisIcon from 'assets/icons/ellipsis-icon.svg';

type OnGetStared = () => void;

type HomeTabOnboardingProps = {
  heading: string;
  nftCard: React.ReactNode;
  onGetStarted: null | OnGetStared;
  subheading: React.ReactNode;
};

function HomeTabOnboardingBase(props: HomeTabOnboardingProps) {
  const { heading, nftCard, onGetStarted, subheading } = props;

  return (
    <Empty.Root css={{ maxWidth: 420 }} variant="tab">
      <Empty.Heading>{heading}</Empty.Heading>
      {subheading}
      {onGetStarted && (
        <Button
          onClick={() => onGetStarted()}
          size={1}
          variant="primary"
          css={{ marginBottom: '$8', marginX: 'auto' }}
        >
          Get started
        </Button>
      )}
      {nftCard}
    </Empty.Root>
  );
}

const HomeTabOnboardingSubheading = styled(Empty.Subheading, {
  marginBottom: '$7',
});

const HomeTabOnboardingMenuIndicatorContainer = styled('div', {
  verticalAlign: 'middle',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '$black5',
  borderRadius: '$round',
  marginX: '$1',
  width: 30,
  height: 30,
});

function HomeTabOnboardingMenuIndicator() {
  return (
    <HomeTabOnboardingMenuIndicatorContainer>
      <EllipsisIcon width={14} />
    </HomeTabOnboardingMenuIndicatorContainer>
  );
}

const HomeTabOnboarding = Object.assign(HomeTabOnboardingBase, {
  /** Secondary heading for onboarding */
  Subheading: HomeTabOnboardingSubheading,
  /** Three dots indicator */
  MenuIndicator: HomeTabOnboardingMenuIndicator,
});

export default HomeTabOnboarding;
