import { z } from 'zod';

import { createBidirectionalMap, setDeepLinkUrlParams } from 'utils/deep-link';
import { ROUTER_PARAMS } from 'utils/router-params';

import { DeepLink } from 'types/DeepLink';
import { TabValue } from 'types/profile';

const TAB_PARAMS_KEY = ROUTER_PARAMS.TAB_DEEP_LINK;

type ProfileUrlParams = { tabValue?: TabValue };

/** User-facing tab values that show in the URL query */
const tabUrlEnum = z.enum([
  'home',
  'collections',
  'created',
  'owned',
  'splits',
  'galleries',
  'hidden',
]);

const tabToUrlMap: Record<TabValue, z.infer<typeof tabUrlEnum>> = {
  home: 'home',
  collections: 'collections',
  created: 'created',
  owned: 'owned',
  splits: 'splits',
  worlds: 'galleries',
  hidden: 'hidden',
};

const tabMap = createBidirectionalMap(tabToUrlMap);

const profileUrlSchema = z.object({
  [TAB_PARAMS_KEY]: tabUrlEnum.optional(),
});

/**
 * Use `get()` to go from URL value to system name, e.g. "galleries" (url) -> "world" (code).
 *
 * Use `set()` to go from system name to URL value, e.g. "word" (code) -> "galleries" (url).
 */
export const profileUrlParams: DeepLink<ProfileUrlParams> = {
  get(query) {
    const result = profileUrlSchema.safeParse(query);
    if (!result.success) return null;

    const parsedData = result.data;

    return {
      tabValue: parsedData.tab ? tabMap.toValue(parsedData.tab) : undefined,
    };
  },
  set(values) {
    const params = new URLSearchParams();

    if (values.tabValue) {
      params.set(TAB_PARAMS_KEY, tabMap.toUrl(values.tabValue));
    }

    setDeepLinkUrlParams(params);
  },
};
