import {
  ApiUpdateUserModerationStatus,
  ApiUpdateUserModerationStatusDocument,
  ApiUpdateUserModerationStatusVariables,
} from 'gql/api/mutations/update-user-moderation-status.generated';
import { fndApiClient } from 'lib/clients/graphql';
import { getAddress } from 'utils/address';

import { ModerationStatus } from 'types/Moderation';

export interface SetProfileModerationProxyProps {
  id: string;
  moderationStatus: ModerationStatus;
  adminAddress: string;
  url: string;
}

export async function setProfileModerationProxy({
  id,
  moderationStatus,
  adminAddress,
  url,
}: SetProfileModerationProxyProps): Promise<{
  done: boolean;
}> {
  const res = await fetch('/api/admin/moderate-profile', {
    method: 'POST',
    body: JSON.stringify({ id, moderationStatus, adminAddress, url }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (res.ok) {
    return await res.json();
  }
  throw new Error('An error occurred at this endpoint');
}

interface SetProfileModerationStatusProps {
  id: string;
  moderationStatus: ModerationStatus;
  authToken: string;
}

export async function setProfileModerationStatus({
  id,
  moderationStatus,
  authToken,
}: SetProfileModerationStatusProps) {
  const client = fndApiClient(authToken);
  return await client.request<
    ApiUpdateUserModerationStatus,
    ApiUpdateUserModerationStatusVariables
  >(ApiUpdateUserModerationStatusDocument, {
    publicKey: getAddress(id),
    moderationStatus,
  });
}
