import NextLink from 'next/link';
import React from 'react';

import Button from 'components/base/Button';
import Flex from 'components/base/Flex';
import { hasToken } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import Mail from 'assets/icons/mail.svg';
import { useSetOneTimeAction } from 'gql/api/mutations/set-one-time-action.generated';
import { useUserEmailExists } from 'gql/api/queries/user-email-exists.generated';
import useUserHasApprovedAction from 'hooks/queries/hasura/markets/use-user-has-approved-action';
import useModal from 'hooks/use-modal';

interface EmailCaptureBlockProps {
  publicKey: string;
}

export default function EmailCaptureBlock(props: EmailCaptureBlockProps) {
  const { publicKey } = props;
  const auth = useAuth();
  const modal = useModal();

  const {
    data: emailExists,
    isLoading: emailExistsIsLoading,
    error,
  } = useUserEmailExists(
    { accountAddress: publicKey },
    {
      enabled: publicKey !== undefined,
      retry: false,
      select: (data): boolean => {
        return Boolean(data.user?.emailExists);
      },
    }
  );

  // has user visited /settings yet?
  const {
    data: userHasVisitedEmailCapture,
    isLoading: captureDataLoading,
    refetch: refetchUserHasVisitedEmailCapture,
  } = useUserHasApprovedAction({
    publicKey,
    actionType: 'VisitEmailCapture',
  });

  // has user dismissed email capture yet?
  const {
    data: userHasDismissedEmailCapture,
    isLoading: dismissedDataLoading,
    refetch: refetchUserHasDismissedEmailCapture,
  } = useUserHasApprovedAction({
    publicKey,
    actionType: 'DismissEmailCapture',
  });

  const { mutateAsync: recordEmailCaptureVisit } = useSetOneTimeAction({
    onSuccess: () => {
      refetchUserHasVisitedEmailCapture();
    },
  });

  const { mutateAsync: recordEmailCaptureDismiss } = useSetOneTimeAction({
    onSuccess: () => {
      refetchUserHasDismissedEmailCapture();
    },
  });

  const onClickRecordVisit = async () => {
    if (!userHasVisitedEmailCapture) {
      await recordEmailCaptureVisit({
        actionType: 'VisitEmailCapture',
      });
    }
  };

  const onClickDismissEmailCapture = async () => {
    await recordEmailCaptureDismiss({
      actionType: 'DismissEmailCapture',
    });
  };

  if (
    error ||
    captureDataLoading ||
    emailExistsIsLoading ||
    emailExists ||
    dismissedDataLoading ||
    userHasDismissedEmailCapture
  ) {
    return null;
  }

  return (
    <Flex>
      <NextLink href="/settings" passHref>
        <Button
          size={0}
          variant="primary"
          icon
          as="a"
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            if (!hasToken(auth)) {
              event.preventDefault();
              // TODO: add "continue" once we have moved user through the auth flow
              modal.setModal({ type: 'AUTH_PRE_SIGN' });
              return;
            }

            onClickRecordVisit();
          }}
        >
          <Mail />
          <span>Set up notifications</span>
        </Button>
      </NextLink>
      <Button
        variant="ghost"
        size={0}
        css={{ marginLeft: '$2' }}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          if (!hasToken(auth)) {
            event.preventDefault();
            // TODO: add "continue" once we have moved user through the auth flow
            modal.setModal({ type: 'AUTH_PRE_SIGN' });
            return;
          }
          onClickDismissEmailCapture();
        }}
      >
        Dismiss
      </Button>
    </Flex>
  );
}
