import { styled } from '@f8n-frontend/stitches';
import { useEffect, useState } from 'react';
import { useCopyToClipboard } from 'react-use';

import ClipboardCheck from 'assets/icons/clipboard-check.svg';
import ClipboardIcon from 'assets/icons/clipboard-icon.svg';

import Tooltip from './Tooltip';

interface CopyIconProps {
  textToCopy: string;
}

export default function CopyToClipboard(props: CopyIconProps) {
  const { textToCopy } = props;
  const [_, copyToClipboard] = useCopyToClipboard();
  const [hasCopied, setHasCopied] = useState(false);

  function handleCopy() {
    setHasCopied(true);
    copyToClipboard(textToCopy);
  }

  useEffect(() => {
    if (!hasCopied) return;

    const timeout = setTimeout(() => {
      setHasCopied(false);
    }, 2500);

    return () => {
      clearTimeout(timeout);
    };
  }, [hasCopied]);

  if (hasCopied) {
    return (
      <Tooltip content="Copied" offset={0}>
        <IconWrapper>
          <ClipboardCheck style={{ display: 'block' }} width={16} height={12} />
        </IconWrapper>
      </Tooltip>
    );
  }

  return (
    <Tooltip content="Copy" offset={0}>
      <IconWrapper onClick={handleCopy} role="button">
        <ClipboardIcon style={{ display: 'block' }} width={16} height={16} />
      </IconWrapper>
    </Tooltip>
  );
}

const IconWrapper = styled('div', {
  display: 'flex',
  paddingX: 10,
  minHeight: 29,
  alignItems: 'center',
  cursor: 'pointer',
  color: '$black20',
  transition: 'color $1 $ease',
  '@hover': {
    '&:hover': {
      color: '$black100',
    },
  },
  '@bp0': { minHeight: 35 },
});
